<template>
  <carousel
    style="margin-top: 57px"
    class="d-md-none"
    :perPage="1"
    :navigation-enabled="true"
    :paginationEnabled="false"
    :navigation-prev-label="prevLabel"
    :navigation-next-label="nextLabel"
  >
    <slide>
      <div
        class="account-types-item d-flex justify-content-center align-items-center"
      >
        <Zero style="position: absolute; top: -55px" />
        <h3 class="zero-header">
          {{ $t("components.account-type.zero.title") }}
        </h3>
        <div class="account-types-content">
          <div
            v-for="detail in $t('components.account-type.zero.details')"
            :key="detail"
            class="account-types-text button-small light-theme-title"
          >
            <CheckIcon />
            {{ detail }}
          </div>

          <b-button variant="primary" class="round-btn-16-24">
            <p class="text-white-button button-medium">
              {{ $t("components.account-type.open account") }}
            </p>
          </b-button>
        </div>
      </div>
    </slide>
    <slide>
      <div
        class="account-types-item account-types-item-low d-flex justify-content-center align-items-center"
      >
        <Core style="position: absolute; top: -65px" />
        <h3 class="zero-header">
          {{ $t("components.account-type.core.title") }}
        </h3>
        <div class="account-types-content">
          <div
            v-for="detail in $t('components.account-type.core.details')"
            :key="detail"
            class="account-types-text button-small light-theme-title"
          >
            <CheckIcon />
            {{ detail }}
          </div>

          <b-button variant="primary" class="round-btn-16-24">
            <p class="text-white-button button-medium">
              {{ $t("components.account-type.open account") }}
            </p>
          </b-button>
        </div>
      </div>
    </slide>
    <slide>
      <div
        class="account-types-item d-flex justify-content-center align-items-center"
      >
        <Nest class="nest-illu" />

        <h3 style="margin-top: -70px">
          {{ $t("components.account-type.nest.title") }}
        </h3>
        <div class="account-types-content">
          <div class="account-types-text button-small light-theme-title">
            {{ $t("components.account-type.nest.details")[0] }}
            <br />
            {{ $t("components.account-type.nest.details")[1] }}
          </div>
        </div>
      </div>
    </slide>

    <!-- <template #back-arrow="{ disabled }">
      <div :class="!disabled ? 'circle' : 'circle-noBorder'">
        <img src="./account-types/leftArrow.svg" class="arrow" />
      </div>
    </template>

    <template #next-arrow="{ disabled }">
      <div :class="!disabled ? 'circle' : 'circle-noBorder'">
        <img src="./account-types/rightArrow.svg" class="arrow" />
      </div>
    </template> -->
  </carousel>
</template>
<script>
import CheckIcon from "@/components/icons/CheckIcon.vue";
import Core from "./account-types/core.vue";
import Zero from "./account-types/zero.vue";
import Nest from "./account-types/nest.vue";
import { Carousel, Slide } from "vue-carousel";
export default {
  components: {
    CheckIcon,
    Core,
    Zero,
    Nest,
    Carousel,
    Slide,
  },
  data() {
    return {
      nextLabel: "",
      prevLabel: "",
    };
  },
};
</script>

<style lang="scss" scoped>
h3 {
  margin-bottom: 0px !important;
  font-size: 20px !important;
}
// PC version
.account-types {
  @media (max-width: 767px) {
    margin-top: 59px;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    margin-top: 67px;
  }
  @media (min-width: 1200px) {
    margin-top: -3%;
  }
  .account-types-items {
    @media screen and (max-width: 767px) {
      margin-top: 16px;
      gap: 17px;
    }

    @media (max-width: 1199px) and (min-width: 768px) {
      margin-top: 62px;
      gap: 32px;
    }
    @media (min-width: 1200px) {
      margin-top: 45px;
      gap: 32px;
    }
  }
}
.account-types-item-low {
  box-shadow: 0px 68px 68px -48px rgba(0, 0, 0, 0.25);
  transform: translateY(-30px);
  @media screen and (max-width: 767px) {
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
    transform: translateY(0);
  }
}
.account-types-item {
  overflow: hidden;
  position: relative;
  // width: 315px !important;
  border: 1px solid #e0e0e0 !important;
  height: 456px !important;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  background: #ffffff;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 24px;

  .account-types-toggle {
    border: 0;
    background: white;
    color: var(--text-color);
    padding: 0 !important;
    display: flex;
    justify-content: space-between;
    width: 100%;
    &:focus {
      box-shadow: none !important;
    }
  }
  .account-types-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .account-types-text {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 12px;
    }
  }
}
// Mobile version
h3 {
  @media screen and (max-width: 767px) {
    font-size: 20px !important;
  }
}
#t {
  @media screen and (max-width: 767px) {
    max-width: 228px;
    margin: auto !important;
  }
}
.round-btn-16-24 {
  height: 48px !important;
  margin: auto !important;
}
.zero-header {
  margin-top: 60px !important;
}
.nest-illu {
  position: absolute;
  left: 0 !important;
  right: 0 !important;
  margin-left: auto !important;
  margin-right: auto !important;
  @media (max-width: 992px) and (min-width: 768px) {
    left: -40px !important;
  }
}
.ssr-carousel-slide {
  display: flex;
  justify-content: space-around !important;
  height: 480px !important;
}
::v-deep .ssr-carousel-back-icon,
::v-deep .ssr-carousel-next-icon {
  width: 40px !important;
  height: 40px !important;
}
::v-deep .ssr-carousel-arrows {
  position: relative;
  width: 50px !important;
  height: 79px !important;
  margin: auto !important;
}
::v-deep .ssr-carousel-back-button,
::v-deep .ssr-carousel-next-button {
  top: 500px !important;
}
::v-deep .ssr-carousel-back-button {
  top: 47px !important;
  left: -25px !important;
  border: none;
  background-color: white;
}
::v-deep .ssr-carousel-next-button {
  top: 47px !important;
  right: -25px !important;
  border: none;
  background-color: white;
}
::v-deep .ssr-carousel-dots {
  display: none !important;
}
.circle {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 40px;
  height: 40px;
  border: 2px solid #07b53b;
  border-radius: 40px;

  /* Inside auto layout */
}
.circle-noBorder {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 40px;
  height: 40px;
  border: 2px solid transparent;
  border-radius: 40px;
}
.arrow {
  width: 14px !important;
  height: 9px !important;
}
::v-deep .VueCarousel-navigation {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 15px;

  .VueCarousel-navigation-button {
    &.VueCarousel-navigation-prev {
      &:before {
        content: url("../../svg/left.svg");
      }
    }
    &.VueCarousel-navigation-next {
      &:before {
        content: url("../../svg/right.svg");
      }
    }
  }
}

::v-deep .VueCarousel-navigation-button {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 2px solid #07b53b;
}
::v-deep .VueCarousel-navigation--disabled {
  filter: grayscale(100%);
  border: none;
}
// ::v-deep .VueCarousel-navigation-next {
//   margin: 0 20px;
// }
// ::v-deep .VueCarousel-navigation-prev {
//   margin: 0 20px;
// }
</style>
