var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "383",
      "height": "261",
      "viewBox": "0 0 383 261",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M196.615 193.101H88.1602C88.1602 179.079 97.3779 167.154 110.213 162.791C113.422 148.36 126.583 137.551 142.335 137.551C158.064 137.551 171.213 148.337 174.434 162.746C187.339 167.074 196.615 179.034 196.615 193.101Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M158.627 180.756H73.0977C73.0977 169.851 80.367 160.575 90.4888 157.182C93.0192 145.958 103.399 137.551 115.821 137.551C128.225 137.551 138.595 145.94 141.135 157.147C151.312 160.513 158.627 169.815 158.627 180.756Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M301.992 70.5659H227.879C227.879 60.9844 234.178 52.835 242.949 49.8539C245.141 39.9921 254.136 32.6055 264.9 32.6055C275.648 32.6055 284.634 39.9765 286.835 49.8227C295.653 52.7805 301.992 60.9532 301.992 70.5659Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M276.029 62.1303H217.582C217.582 54.6779 222.55 48.3395 229.466 46.0209C231.196 38.3506 238.288 32.6055 246.777 32.6055C255.254 32.6055 262.34 38.3385 264.076 45.9967C271.03 48.2971 276.029 54.6537 276.029 62.1303Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M86.4375 4.31055C97.9634 19.6184 128.579 46.7763 158.835 32.9452C196.654 15.6563 243.658 31.3244 233.933 78.8687C224.208 126.413 84.2579 131.506 121.555 220.421C148.902 285.614 283.534 247.496 290.018 147.545",
      "stroke": "#ED6400",
      "stroke-width": "1.1619",
      "stroke-linecap": "round",
      "stroke-dasharray": "2.81 2.81"
    }
  }), _c('rect', {
    attrs: {
      "y": "4.5",
      "width": "113.963",
      "height": "59.9804",
      "rx": "5.99804",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M9.78906 70.1953H89.513",
      "stroke": "#07B53B",
      "stroke-width": "1.1619",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M9.53906 74.9434H57.5234",
      "stroke": "#07B53B",
      "stroke-width": "1.1619",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M259.426 255.047H339.15",
      "stroke": "#07B53B",
      "stroke-width": "1.1619",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M259.176 259.795H307.16",
      "stroke": "#07B53B",
      "stroke-width": "1.1619",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "x": "7.07314",
      "y": "0.58095",
      "width": "112.801",
      "height": "58.8185",
      "rx": "5.41709",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "1.1619"
    }
  }), _c('rect', {
    attrs: {
      "x": "50.4688",
      "y": "16.4902",
      "width": "26.1751",
      "height": "2.24929",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "50.4688",
      "y": "23.2383",
      "width": "26.1751",
      "height": "2.24929",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "50.4688",
      "y": "29.9863",
      "width": "17.7617",
      "height": "2.24929",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "50.4688",
      "y": "36.7305",
      "width": "13.0875",
      "height": "2.24929",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "29.9671",
      "cy": "21.4886",
      "r": "4.99837",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M19.4717 39.4819C19.4717 38.1035 19.7432 36.7386 20.2707 35.4651C20.7982 34.1916 21.5714 33.0345 22.5461 32.0598C23.5208 31.0851 24.6779 30.3119 25.9514 29.7844C27.2249 29.2569 28.5898 28.9854 29.9683 28.9854C31.3467 28.9854 32.7116 29.2569 33.9851 29.7844C35.2586 30.3119 36.4158 31.0851 37.3905 32.0598C38.3652 33.0344 39.1383 34.1916 39.6658 35.4651C40.1933 36.7386 40.4648 38.1035 40.4648 39.4819L29.9683 39.4819L19.4717 39.4819Z",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "247.043",
      "y": "124.189",
      "width": "104.916",
      "height": "125.487",
      "fill": "#07B53B"
    }
  }), _c('rect', {
    attrs: {
      "x": "256.292",
      "y": "118.698",
      "width": "103.754",
      "height": "124.325",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "1.1619"
    }
  }), _c('rect', {
    attrs: {
      "x": "284.022",
      "y": "110.899",
      "width": "47.369",
      "height": "15.3039",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "1.1619"
    }
  }), _c('path', {
    attrs: {
      "d": "M260.781 13.6055L275.914 17.3904",
      "stroke": "#ED6400",
      "stroke-width": "1.1619",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M266.457 23.0684L270.242 7.93528",
      "stroke": "#ED6400",
      "stroke-width": "1.1619",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M193.434 215.348L208.567 219.133",
      "stroke": "#ED6400",
      "stroke-width": "1.1619",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M199.109 224.811L202.894 209.677",
      "stroke": "#ED6400",
      "stroke-width": "1.1619",
      "stroke-linecap": "round"
    }
  }), _c('circle', {
    attrs: {
      "cx": "153.598",
      "cy": "228.336",
      "r": "5.0521",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.1619"
    }
  }), _c('circle', {
    attrs: {
      "cx": "231.99",
      "cy": "171.072",
      "r": "3.8998",
      "fill": "#07B53B"
    }
  }), _c('circle', {
    attrs: {
      "cx": "83.3959",
      "cy": "98.3432",
      "r": "3.8998",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M301.328 180.699L308.016 187.387L321.39 174.012",
      "stroke": "#07B53B",
      "stroke-width": "1.73325",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('circle', {
    attrs: {
      "cx": "311.176",
      "cy": "180.516",
      "r": "22.8175",
      "stroke": "#ED6400",
      "stroke-width": "1.1619"
    }
  }), _c('circle', {
    attrs: {
      "cx": "377.47",
      "cy": "182.684",
      "r": "4.76643",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "166.476",
      "y": "100.115",
      "width": "52.5787",
      "height": "29.1513",
      "rx": "2.80587",
      "transform": "rotate(-12.4135 166.476 100.115)",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "0.969651"
    }
  }), _c('rect', {
    attrs: {
      "x": "175.02",
      "y": "102.106",
      "width": "52.5787",
      "height": "29.1513",
      "rx": "2.80587",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "0.969651"
    }
  }), _c('rect', {
    attrs: {
      "x": "174.535",
      "y": "113.893",
      "width": "53.5484",
      "height": "4.46236",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "211.91",
      "y": "106.363",
      "width": "13.3871",
      "height": "4.18347",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }