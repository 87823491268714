<template>
  <div>
    <h2 id="t" class="text-center light-theme-title">
      {{ $t("account-type.pick favorite.title") }}
    </h2>
    <div class="account-types">
      <div
        class="account-types-items d-none d-md-flex flex-column flex-md-row justify-content-center align-items-center"
      >
        <!-- PC version -->
        <!-- Standard -->
        <div
          class="account-types-item d-flex justify-content-center align-items-center"
        >
          <Zero style="position: absolute; top: -55px" />
          <h3 class="zero-header">
            {{ $t("components.account-type.zero.title") }}
          </h3>
          <div class="account-types-content">
            <div
              v-for="detail in $t('components.account-type.zero.details')"
              :key="detail"
              class="account-types-text light-theme-title"
            >
              <CheckIcon />
              {{ detail }}
            </div>

            <b-button
              v-if="portal_url"
              variant="primary"
              class="round-btn-16-24"
              @click="goToSignUp"
            >
              <p class="text-white-button button-medium">
                {{ $t("components.account-type.open account") }}
              </p>
            </b-button>
          </div>
        </div>

        <!-- Low-spread -->

        <div
          class="account-types-item account-types-item-low d-flex justify-content-center align-items-center"
        >
          <Core style="position: absolute; top: -65px" />
          <h3 class="zero-header">
            {{ $t("components.account-type.core.title") }}
          </h3>
          <div class="account-types-content">
            <div
              v-for="detail in $t('components.account-type.core.details')"
              :key="detail"
              class="account-types-text light-theme-title"
            >
              <CheckIcon />
              {{ detail }}
            </div>

            <b-button
              v-if="portal_url"
              variant="primary"
              class="round-btn-16-24"
              @click="goToSignUp"
            >
              <p class="text-white-button button-medium">
                {{ $t("components.account-type.open account") }}
              </p>
            </b-button>
          </div>
        </div>

        <div
          class="account-types-item d-flex justify-content-center align-items-center"
        >
          <Cent style="position: absolute; top: -55px" />
          <h3 class="zero-header">
            {{ $t("components.account-type.cent.title") }}
          </h3>
          <div class="account-types-content">
            <div
              v-for="detail in $t('components.account-type.cent.details')"
              :key="detail"
              class="account-types-text light-theme-title"
            >
              <CheckIcon />
              {{ detail }}
            </div>

            <b-button
              v-if="portal_url"
              variant="primary"
              class="round-btn-16-24"
              @click="goToSignUp"
            >
              <p class="text-white-button button-medium">
                {{ $t("components.account-type.open account") }}
              </p>
            </b-button>
          </div>
        </div>
      </div> 

      <div class="d-flex d-md-none flex-column">
        <div class="box" @click="closeOther(0)">
          <span>
            {{ $t("components.account-type.zero.title") }}
          </span>
          <ChevronUp v-if="boxes[0]" />
          <ChevronDown v-else />
        </div>
        <div
          v-if="boxes[0]"
          class="account-types-item d-flex justify-content-center align-items-center"
        >
          <Zero style="position: absolute; top: -65px; left: 30px" />
          <h3 class="zero-header">
            {{ $t("components.account-type.zero.title") }}
          </h3>
          <div class="account-types-content">
            <div
              v-for="detail in $t('components.account-type.zero.details')"
              :key="detail"
              class="account-types-text light-theme-title"
            >
              <CheckIcon />
              {{ detail }}
            </div>

            <b-button
              v-if="portal_url"
              variant="primary"
              class="round-btn-16-24"
              @click="goToSignUp"
            >
              <p class="text-white-button button-medium">
                {{ $t("components.account-type.open account") }}
              </p>
            </b-button>
          </div>
        </div>

        <!-- Low-spread -->
        <div class="box" @click="closeOther(1)">
          <span>
            {{ $t("components.account-type.core.title") }}
          </span>
          <ChevronUp v-if="boxes[1]" />
          <ChevronDown v-else />
        </div>
        <div
          v-if="boxes[1]"
          class="account-types-item account-types-item-low d-flex justify-content-center align-items-center"
        >
          <Core style="position: absolute; top: -65px" />
          <h3 class="zero-header">
            {{ $t("components.account-type.core.title") }}
          </h3>
          <div class="account-types-content">
            <div
              v-for="detail in $t('components.account-type.core.details')"
              :key="detail"
              class="account-types-text light-theme-title"
            >
              <CheckIcon />
              {{ detail }}
            </div>

            <b-button
              v-if="portal_url"
              variant="primary"
              class="round-btn-16-24"
              @click="goToSignUp"
            >
              <p class="text-white-button button-medium">
                {{ $t("components.account-type.open account") }}
              </p>
            </b-button>
          </div>
        </div>

        <!-- Bonus -->
        <div class="box" @click="closeOther(2)">
          <span>
            {{ $t("components.account-type.cent.title") }}
          </span>
          <ChevronUp v-if="boxes[2]" />
          <ChevronDown v-else />
        </div>
        <div
          v-if="boxes[2]"
          class="account-types-item account-types-item-low d-flex justify-content-center align-items-center"
        >
          <Cent style="position: absolute; top: -65px" />
          <h3 class="zero-header">
            {{ $t("components.account-type.cent.title") }}
          </h3>
          <div class="account-types-content">
            <div
              v-for="detail in $t('components.account-type.cent.details')"
              :key="detail"
              class="account-types-text light-theme-title"
            >
              <CheckIcon />
              {{ detail }}
            </div>

            <b-button
              v-if="portal_url"
              variant="primary"
              class="round-btn-16-24"
              @click="goToSignUp"
            >
              <p class="text-white-button button-medium">
                {{ $t("components.account-type.open account") }}
              </p>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckIcon from "@/components/icons/CheckIcon.vue";
import TimeIcon from "@/components/icons/TimeIcon.vue";
import ChevronDown from "@/components/icons/ChevronDown.vue";
import Core from "../svg/account-types/core.vue";
import Zero from "../svg/account-types/zero.vue";
import Cent from "../svg/account-types/cent.vue";
import Carousel from "@/components/account-types-carousel/AccountTypesCarousel.vue";
import ChevronUp from "@/components/icons/ChevronUp.vue";
import { mapActions, mapGetters } from "vuex";


export default {
  data() {
    return {
      boxes: Array(3).fill(false)
    };
  },
  components: {
    CheckIcon,
    TimeIcon,
    ChevronDown,
    ChevronUp,
    Core,
    Zero,
    Cent,
    Carousel,
  },
  computed: {
    ...mapGetters({
      portal_url: "portal_url",
    }),
  },
  methods: {
    goToSignUp() {
      if (this.portal_url)
        window.location.href = `${this.portal_url}/register`;
    },
    closeOther(index) {
      this.boxes = this.boxes.map((i, ix) => ix != index ? false : !i);
    }
  },
};
</script>
<style lang="scss" scoped>
h3 {
  margin-bottom: 0px !important;
  font-size: 20px !important;
}
// PC version
.account-types {
  @media (max-width: 768px) {
    margin-top: 59px;
  }

  @media (max-width: 1200px) and (min-width: 768px) {
    margin-top: 67px;
  }
  @media (min-width: 1200px) {
    // margin-top: -3%;
  }
  .account-types-items {
    @media screen and (max-width: 767px) {
      margin-top: 16px;
      gap: 17px;
    }

    @media (max-width: 1200px) and (min-width: 768px) {
      margin-top: 62px;
      gap: 32px;
    }
    @media (min-width: 1200px) {
      margin-top: 94px;
      gap: 32px;
    }
  }
}
.account-types-item-low {
  box-shadow: 0px 68px 68px -48px rgba(0, 0, 0, 0.25);
  transform: translateY(-30px);
  @media screen and (max-width: 767px) {
    box-shadow: none;
    transform: translateY(0);
  }
}
.account-types-item {
  overflow: hidden;
  position: relative;
  // width: 315px !important;
  height: 453px !important;
  display: flex;
  flex-direction: column;

  gap: 20px;
  background: #ffffff;
  border-radius: 24px;

  @media (max-width: 767px) {
    height: 4;
  }

  @media screen and (min-width: 1200px) {
    width: 315px !important;
    border: 1px solid #e0e0e0 !important;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    width: 270px !important;
    border: 1px solid #e0e0e0 !important;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
  }

  .account-types-toggle {
    border: 0;
    background: white;
    color: var(--text-color);
    padding: 0 !important;
    display: flex;
    justify-content: space-between;
    width: 100%;
    &:focus {
      box-shadow: none !important;
    }
  }
  .account-types-content {
    display: flex;
    flex-direction: column;

    gap: 16px;
    @media (max-width: 767px) {
      gap: 17px;
      margin-bottom: 20px;
    }
    .account-types-text {
      //font-family: "Open Sans";
      letter-spacing: 0.02em;

      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 12px;

      font-weight: 600;
      @media screen and (min-width: 1200px) {
        font-size: 14px;
      }
      @media (max-width: 1199px) and (min-width: 767px) {
        font-size: 12px;
      }
      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
}
// Mobile version
h3 {
  @media screen and (max-width: 767px) {
    font-size: 20px !important;
  }
}
#t {
  @media screen and (max-width: 767px) {
    max-width: 228px;
    margin: 120px auto 60px auto !important;
  }
}
.round-btn-16-24 {
  height: 48px !important;
  margin: 16px auto auto auto !important;
  @media (max-width: 767px) {
  }
}
.zero-header {
  margin-top: 60px !important;
}
.nest-illu {
  position: absolute;

  // z-index: 0;
  // margin-left: auto !important;
  // margin-right: auto !important;
  @media (max-width: 992px) and (min-width: 768px) {
    left: -40px !important;
  }
}
// .ssr-carousel-slide {
//   display: flex;
//   justify-content: space-around !important;
//   height: 480px !important;
// }
// ::v-deep .ssr-carousel-back-icon,
// ::v-deep .ssr-carousel-next-icon {
//   width: 40px !important;
//   height: 40px !important;
// }
// ::v-deep .ssr-carousel-arrows {
//   position: relative;
//   width: 50px !important;
//   height: 79px !important;
//   margin: auto !important;
// }
// ::v-deep .ssr-carousel-back-button,
// ::v-deep .ssr-carousel-next-button {
//   top: 500px !important;
// }
// ::v-deep .ssr-carousel-back-button {
//   top: 47px !important;
//   left: -25px !important;
// }
// ::v-deep .ssr-carousel-next-button {
//   top: 47px !important;
//   right: -25px !important;
// }
// ::v-deep .ssr-carousel-dots {
//   display: none !important;
// }
.circle {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 40px;
  height: 40px;
  border: 2px solid #e6e8ec;
  border-radius: 40px;

  /* Inside auto layout */
}
.circle-noBorder {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 40px;
  height: 40px;
  border: 2px solid transparent;
  border-radius: 40px;
}
.arrow {
  width: 14px !important;
  height: 9px !important;
}
h2 {
  margin-top: 90px !important;
  margin-bottom: 80px !important;
}

.box {
  display: flex;
  justify-content: space-between;
  padding: 24px 26px;

  margin: 0 auto 40px auto;

  width: 311px;
  height: 84px;

  background: #ffffff;
  border: 2px solid #07b53b;
  box-shadow: 0px 8px 16px rgba(218, 225, 233, 0.56);
  border-radius: 18px;
  font-weight: 400;
  font-size: 20px;
  @media (max-width: 767px) {
    margin-bottom: 16px !important;
  box-shadow: none;

  }
}

</style>
