var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M21.6095 13.0572C21.0888 12.5365 20.2446 12.5365 19.7239 13.0572L16 16.781L12.2761 13.0572C11.7554 12.5365 10.9112 12.5365 10.3905 13.0572C9.86982 13.5779 9.86982 14.4221 10.3905 14.9428L15.0572 19.6095C15.5779 20.1302 16.4221 20.1302 16.9428 19.6095L21.6095 14.9428C22.1302 14.4221 22.1302 13.5779 21.6095 13.0572Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }