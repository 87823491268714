var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "275",
      "height": "362",
      "viewBox": "0 0 275 362",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M157.138 4.1142C150.831 2.61953 144.322 1.96432 137.728 2.29305C131.741 2.59189 125.669 3.75893 119.57 5.85195C92.2812 15.2124 69.6101 36.4582 51.8283 58.5642C45.9107 65.8943 40.5433 73.6576 35.6969 81.7094",
      "stroke": "#07B53B",
      "stroke-width": "2.97423",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M221.388 51.2059C214.085 41.958 206.06 33.4635 197.313 25.8673C192.07 21.3037 186.482 17.2326 180.634 13.7695",
      "stroke": "#07B53B",
      "stroke-width": "2.97423",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M256.398 114.057C247.8 91.2409 236.052 69.7604 221.388 51.207",
      "stroke": "#07B53B",
      "stroke-width": "2.97423",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M272.683 226.068C275.72 189.546 269.989 150.116 256.396 114.055",
      "stroke": "#07B53B",
      "stroke-width": "2.97423",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M267.585 256.719C269.339 250.148 270.658 243.404 271.573 236.545C272.03 233.072 272.401 229.599 272.684 226.068",
      "stroke": "#07B53B",
      "stroke-width": "2.97423",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M103.396 354.318C114.392 357.57 125.791 359.404 137.245 359.849C186.851 361.738 227.158 335.69 250.582 296.966",
      "stroke": "#07B53B",
      "stroke-width": "2.97423",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M58.9307 330.748C60.9574 332.076 63.0419 333.346 65.1841 334.559",
      "stroke": "#07B53B",
      "stroke-width": "2.97423",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M26.2239 99.0849C21.6399 108.467 17.6639 118.167 14.2666 128.04C-7.58509 191.877 -1.15068 273.437 47.5454 321.537",
      "stroke": "#07B53B",
      "stroke-width": "2.97423",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M36.3217 307.097L94.9908 261.647L120.651 266.068L161.486 227.71L234.743 238.375",
      "stroke": "#07B53B",
      "stroke-width": "2.97423",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M103.542 262.378L103.505 238.956L81.5369 218.171L97.104 188.032",
      "stroke": "#07B53B",
      "stroke-width": "2.97423",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }