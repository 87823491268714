var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "16",
      "height": "17",
      "viewBox": "0 0 16 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0_532_44426)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M6.80533 12.5C6.50111 12.5 6.21298 12.384 6.02835 12.184L3.5296 9.486C3.45234 9.40268 3.39594 9.30774 3.36362 9.20659C3.3313 9.10545 3.32369 9.00009 3.34122 8.89654C3.35876 8.79299 3.4011 8.69329 3.46582 8.60312C3.53054 8.51295 3.61638 8.43409 3.71842 8.37104C3.82042 8.30774 3.93672 8.2615 4.06063 8.23499C4.18454 8.20848 4.31364 8.20221 4.44052 8.21654C4.5674 8.23088 4.68958 8.26553 4.80004 8.31852C4.9105 8.37151 5.00708 8.4418 5.08424 8.52534L6.7284 10.2992L10.8622 4.87472C10.9993 4.69561 11.2178 4.56826 11.4696 4.52058C11.7215 4.47291 11.9862 4.50881 12.2057 4.62041C12.6623 4.85243 12.8029 5.3439 12.5176 5.71765L7.63335 12.124C7.54997 12.2338 7.43505 12.3254 7.29856 12.3907C7.16206 12.456 7.00812 12.4932 6.85008 12.4989C6.8347 12.5 6.82071 12.5 6.80533 12.5Z",
      "fill": "#39454C"
    }
  })]), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "clip0_532_44426"
    }
  }, [_c('rect', {
    attrs: {
      "width": "9.92",
      "height": "8.32",
      "fill": "white",
      "transform": "translate(3.03906 4.33984)"
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }