var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "309",
      "height": "211",
      "viewBox": "0 0 309 211",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M158.862 156.023H71.2344C71.2344 144.694 78.682 135.059 89.0521 131.534C91.6447 119.874 102.279 111.141 115.006 111.141C127.714 111.141 138.339 119.856 140.94 131.497C151.367 134.994 158.862 144.657 158.862 156.023Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M128.171 146.049H59.0664C59.0664 137.238 64.9398 129.744 73.1178 127.002C75.1623 117.933 83.5486 111.141 93.5853 111.141C103.607 111.141 111.986 117.919 114.038 126.974C122.261 129.694 128.171 137.209 128.171 146.049Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M244.006 57.0203H184.125C184.125 49.2788 189.214 42.6943 196.301 40.2857C198.073 32.3177 205.339 26.3496 214.036 26.3496C222.721 26.3496 229.981 32.3052 231.759 40.2605C238.884 42.6503 244.006 49.2536 244.006 57.0203Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M223.028 50.2046H175.805C175.805 44.1834 179.818 39.0622 185.407 37.1888C186.804 30.9915 192.535 26.3496 199.393 26.3496C206.242 26.3496 211.968 30.9817 213.37 37.1692C218.989 39.0279 223.028 44.1638 223.028 50.2046Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M69.8438 3.48828C79.1563 15.8565 103.893 37.7991 128.338 26.6241C158.895 12.6553 196.872 25.3145 189.015 63.7286C181.157 102.143 68.0827 106.258 98.2178 178.098C120.313 230.772 229.091 199.973 234.329 119.216",
      "stroke": "#ED6400",
      "stroke-width": "0.85606",
      "stroke-linecap": "round",
      "stroke-dasharray": "2.07 2.07"
    }
  }), _c('rect', {
    attrs: {
      "y": "3.63672",
      "width": "92.0779",
      "height": "48.462",
      "rx": "4.41922",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M7.91797 56.7168H72.3321",
      "stroke": "#07B53B",
      "stroke-width": "0.85606",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M7.71484 60.5527H46.4845",
      "stroke": "#07B53B",
      "stroke-width": "0.85606",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M209.605 206.066H274.02",
      "stroke": "#07B53B",
      "stroke-width": "0.85606",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M209.402 209.902H248.172",
      "stroke": "#07B53B",
      "stroke-width": "0.85606",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "x": "5.67022",
      "y": "0.42803",
      "width": "91.2218",
      "height": "47.606",
      "rx": "3.99119",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "0.85606"
    }
  }), _c('rect', {
    attrs: {
      "x": "40.7852",
      "y": "13.332",
      "width": "21.1485",
      "height": "1.81735",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "40.7852",
      "y": "18.7852",
      "width": "21.1485",
      "height": "1.81735",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "40.7852",
      "y": "24.2363",
      "width": "14.3508",
      "height": "1.81735",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "40.7852",
      "y": "29.6855",
      "width": "10.5743",
      "height": "1.81735",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "24.2221",
      "cy": "17.3705",
      "r": "4.0385",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M15.7453 31.9098C15.7453 30.796 15.9647 29.6932 16.3909 28.6643C16.8171 27.6353 17.4418 26.7004 18.2293 25.9129C19.0168 25.1254 19.9517 24.5007 20.9807 24.0745C22.0096 23.6483 23.1125 23.4289 24.2262 23.4289C25.3399 23.4289 26.4427 23.6483 27.4717 24.0745C28.5006 24.5007 29.4355 25.1254 30.223 25.9129C31.0106 26.7004 31.6353 27.6353 32.0615 28.6643C32.4877 29.6932 32.707 30.796 32.707 31.9098L24.2262 31.9098L15.7453 31.9098Z",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "199.598",
      "y": "100.346",
      "width": "84.7681",
      "height": "101.389",
      "fill": "#07B53B"
    }
  }), _c('rect', {
    attrs: {
      "x": "207.041",
      "y": "95.8714",
      "width": "83.912",
      "height": "100.533",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "0.85606"
    }
  }), _c('rect', {
    attrs: {
      "x": "229.444",
      "y": "89.5706",
      "width": "38.3552",
      "height": "12.4478",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "0.85606"
    }
  }), _c('path', {
    attrs: {
      "d": "M210.707 10.9961L222.934 14.0542",
      "stroke": "#ED6400",
      "stroke-width": "0.85606",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M215.293 18.6445L218.351 6.41754",
      "stroke": "#ED6400",
      "stroke-width": "0.85606",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M156.293 173.996L168.52 177.054",
      "stroke": "#ED6400",
      "stroke-width": "0.85606",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M160.879 181.645L163.937 169.418",
      "stroke": "#ED6400",
      "stroke-width": "0.85606",
      "stroke-linecap": "round"
    }
  }), _c('circle', {
    attrs: {
      "cx": "124.102",
      "cy": "184.493",
      "r": "4.12328",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "0.85606"
    }
  }), _c('circle', {
    attrs: {
      "cx": "187.444",
      "cy": "138.225",
      "r": "3.1509",
      "fill": "#07B53B"
    }
  }), _c('circle', {
    attrs: {
      "cx": "67.3853",
      "cy": "79.4614",
      "r": "3.1509",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M243.465 146.003L248.868 151.406L259.674 140.6",
      "stroke": "#07B53B",
      "stroke-width": "1.27702",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('circle', {
    attrs: {
      "cx": "251.421",
      "cy": "145.854",
      "r": "18.4771",
      "stroke": "#ED6400",
      "stroke-width": "0.85606"
    }
  }), _c('circle', {
    attrs: {
      "cx": "304.984",
      "cy": "147.607",
      "r": "3.8511",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "134.473",
      "y": "80.8717",
      "width": "42.5508",
      "height": "23.6223",
      "rx": "2.0673",
      "transform": "rotate(-12.4135 134.473 80.8717)",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "0.714416"
    }
  }), _c('rect', {
    attrs: {
      "x": "141.381",
      "y": "82.4705",
      "width": "42.5508",
      "height": "23.6223",
      "rx": "2.0673",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "0.714416"
    }
  }), _c('rect', {
    attrs: {
      "x": "141.023",
      "y": "92.0273",
      "width": "43.2652",
      "height": "3.60543",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "171.223",
      "y": "85.9453",
      "width": "10.8163",
      "height": "3.38009",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }