<template>
  <div class="hero-custom">
    <div class="hero-custom__content mx-auto">
      <h1 class="hero-custom__title">
        <span class="text-primary">

          {{ $t("account-type.hero.header") }}
        </span>
        <span class="light-theme-title">
          {{ $t("account-type.hero.header-green") }}
        </span>
      </h1>
      <h2 class="hero-custom__text hero-subtitle text-grey-primary-main-500">
        {{ $t("account-type.hero.sub-header1") }}
        <br class="d-none d-md-inline" />
        {{ $t("account-type.hero.sub-header2") }}
      </h2>

      <div class="hero-buttons d-flex">
        <a href="https://nozax.kb.help/accounts" target="_blank">
          <b-button variant="primary" class="round-btn-16-24">
            <p class="button-medium text-white-button">
              {{ $t("account-type.hero.learn more") }}
            </p>
          </b-button>
        </a>
      </div>
    </div>

    <div class="d-none d-xl-block">
      <HomeHero />
    </div>
    <div class="d-none d-md-block d-xl-none">
      <HomeTabletHero />
    </div>

    <div class="d-block d-md-none">
      <HomeMobileHero />
    </div>
  </div>
</template>

<script>
import HomeHero from "../svg/hero/HomeHero.vue";
import HomeTabletHero from "../svg/hero/HomeTabletHero.vue";
import HomeMobileHero from "../svg/hero/HomeMobileHero.vue";

export default {
  components: {
    HomeHero,
    HomeTabletHero,
    HomeMobileHero,
  },
};
</script>

<style lang="scss" scoped>
.hero-content {
  @media (max-width: 767px) {
  }

  @media (max-width: 1199px) and (min-width: 992px) {
    margin-top: 70px;
  }
  @media (min-width: 1200px) {
    margin-top: 7%;
  }
}
.bt {
  padding: 11px 24px;

  width: 179px;
  height: 50px;

  border-radius: 90px;
  @media (max-width: 767px) {
    margin-top: 31px;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
  }
  @media (min-width: 1200px) {
  }
}
h1 {
  @media (max-width: 767px) {
    max-width: 305px !important;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
  }
  @media (min-width: 1200px) {
  }
}
h4 {
  @media (max-width: 767px) {
    max-width: 270px !important;
  }

  @media (max-width: 991px) and (min-width: 768px) {
    max-width: 400px !important;
  }
  @media (min-width: 1200px) {
  }
}
.round-btn-16-24 {
  @media (max-width: 767px) {
    margin-top: 8px;
    margin-bottom: 71px;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
  }
  @media (min-width: 1200px) {
  }
}

.hero-custom__text {
  width: 470px;
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 390px;
  }
  @media (max-width: 991px) {
    width: 100%;
    padding-right: 32px !important;
  }
  @media (max-width: 767px) {
    padding-right: 40px;
  }
}
</style>
