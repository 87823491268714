export const Depo = [
  {
    depositMethod: "Bank Transfer",
    acceptedCurrencies: "EUS/USD/JPY ",
    commissionAndFees: "Free",
    minimumAmount: "$100",
    processingTime: "3",
  },
  {
    depositMethod: "Visa and Mastercard",
    acceptedCurrencies: "EUS/USD/JPY ",
    commissionAndFees: "Free",
    minimumAmount: "No Minimum",
    processingTime: "Instant",
  },
  {
    depositMethod: "Skrill",
    acceptedCurrencies: "EUS/USD/JPY ",
    commissionAndFees: "Free",
    minimumAmount: "No Minimum",
    processingTime: "Instant",
  },
  {
    depositMethod: "Neteller",
    acceptedCurrencies: "EUS/USD/JPY ",
    commissionAndFees: "Free",
    minimumAmount: "No Minimum",
    processingTime: "Instant",
  },
  {
    depositMethod: "Local Bank",
    acceptedCurrencies: "EUS/USD/JPY ",
    commissionAndFees: "Free",
    minimumAmount: "No Minimum",
    processingTime: "Instant",
  },
];

export const Withdr = [
  {
    withdrawMethod: "Bank Transfer",
    acceptedCurrencies: "EUS/USD/JPY ",
    commissionAndFees: "Free",
    minimumAmount: "$100",
    processingTime: "3",
  },
  {
    withdrawMethod: "Visa and Mastercard",
    acceptedCurrencies: "EUS/USD/JPY ",
    commissionAndFees: "Free",
    minimumAmount: "No Minimum",
    processingTime: "Instant",
  },
  {
    withdrawMethod: "Skrill",
    acceptedCurrencies: "EUS/USD/JPY ",
    commissionAndFees: "Free",
    minimumAmount: "No Minimum",
    processingTime: "Instant",
  },
  {
    withdrawMethod: "Neteller",
    acceptedCurrencies: "EUS/USD/JPY ",
    commissionAndFees: "Free",
    minimumAmount: "No Minimum",
    processingTime: "Instant",
  },
  {
    withdrawMethod: "Local Bank",
    acceptedCurrencies: "EUS/USD/JPY ",
    commissionAndFees: "Free",
    minimumAmount: "No Minimum",
    processingTime: "Instant",
  },
];
