var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "deposit section"
  }, [_c('h2', {
    staticClass: "light-theme-title text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t("account-type.deposit and withdrawal.title")) + " ")]), _c('div', {
    staticClass: "deposit-buttons",
    staticStyle: {
      "margin-bottom": "39px"
    }
  }, [_c('div', {
    staticClass: "deposit-button",
    staticStyle: {
      "text-align": "center"
    },
    style: _vm.type == 'deposit' ? 'border-bottom: 3px solid #07b53b;' : '',
    on: {
      "click": function ($event) {
        return _vm.handleClick('deposit');
      }
    }
  }, [_c('p', {
    staticClass: "light-theme-title button-1 roboto"
  }, [_vm._v(" " + _vm._s(_vm.$t("account-type.deposit and withdrawal.deposit.title")) + " ")])]), _c('div', {
    staticClass: "deposit-button",
    staticStyle: {
      "text-align": "center"
    },
    style: _vm.type == 'withdrawal' ? 'border-bottom: 3px solid #07b53b' : '',
    on: {
      "click": function ($event) {
        return _vm.handleClick('withdrawal');
      }
    }
  }, [_c('p', {
    staticClass: "light-theme-title button-1 roboto"
  }, [_vm._v(" " + _vm._s(_vm.$t("account-type.deposit and withdrawal.withdrawal.title")) + " ")])])]), _vm.type === 'deposit' ? _c('div', {
    staticClass: "d-none d-md-block"
  }, [_c('b-table', {
    staticClass: "table table-borderless",
    attrs: {
      "responsive": "",
      "items": _vm.depoItems,
      "fields": _vm.depoFields,
      "thead-class": "header",
      "tbody-tr-class": "body-row",
      "thead-tr-class": "head-row"
    },
    scopedSlots: _vm._u([{
      key: "cell(index)",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(depositMethod)",
      fn: function (data) {
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-wrap": "nowrap",
            "align-items": "center"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t(("account-type.deposit and withdrawal.methods." + (data.value)))) + " ")])];
      }
    }, {
      key: "cell(acceptedCurrencies)",
      fn: function (data) {
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-wrap": "nowrap",
            "align-items": "center"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t(("account-type.deposit and withdrawal.deposit.accept currencies." + (data.item.depositMethod.toLowerCase())))) + " ")])];
      }
    }, {
      key: "cell(commissionAndFees)",
      fn: function (data) {
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-wrap": "nowrap",
            "align-items": "center"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t(("account-type.deposit and withdrawal.deposit.commission and fees." + (data.item.depositMethod.toLowerCase())))) + " ")])];
      }
    }, {
      key: "cell(minimumAmount)",
      fn: function (data) {
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-wrap": "nowrap",
            "align-items": "center"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t(("account-type.deposit and withdrawal.deposit.minimum amount." + (data.item.depositMethod.toLowerCase())))) + " ")])];
      }
    }, {
      key: "cell(processingTime)",
      fn: function (data) {
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-wrap": "nowrap",
            "align-items": "center"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t(("account-type.deposit and withdrawal.deposit.processing time." + (data.item.depositMethod.toLowerCase())))) + " ")])];
      }
    }], null, false, 4075153139)
  })], 1) : _vm._e(), _vm.type === 'withdrawal' ? _c('div', {
    staticClass: "d-none d-md-block"
  }, [_c('b-table', {
    staticClass: "table table-borderless",
    attrs: {
      "responsive": "",
      "items": _vm.withdrItems,
      "fields": _vm.withdrFields,
      "thead-class": "header",
      "tbody-tr-class": "body-row",
      "thead-tr-class": "head-row"
    },
    scopedSlots: _vm._u([{
      key: "cell(index)",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(withdrawMethod)",
      fn: function (data) {
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-wrap": "nowrap",
            "align-items": "center"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t(("account-type.deposit and withdrawal.methods." + (data.value)))) + " ")])];
      }
    }, {
      key: "cell(acceptedCurrencies)",
      fn: function (data) {
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-wrap": "nowrap",
            "align-items": "center"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t(("account-type.deposit and withdrawal.withdrawal.accept currencies." + (data.item.withdrawMethod.toLowerCase())))) + " ")])];
      }
    }, {
      key: "cell(commissionAndFees)",
      fn: function (data) {
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-wrap": "nowrap",
            "align-items": "center"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t(("account-type.deposit and withdrawal.withdrawal.commission and fees." + (data.item.withdrawMethod.toLowerCase())))) + " ")])];
      }
    }, {
      key: "cell(minimumAmount)",
      fn: function (data) {
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-wrap": "nowrap",
            "align-items": "center"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t(("account-type.deposit and withdrawal.withdrawal.minimum amount." + (data.item.withdrawMethod.toLowerCase())))) + " ")])];
      }
    }, {
      key: "cell(processingTime)",
      fn: function (data) {
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-wrap": "nowrap",
            "align-items": "center"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t(("account-type.deposit and withdrawal.withdrawal.processing time." + (data.item.withdrawMethod.toLowerCase())))) + " ")])];
      }
    }], null, false, 831320801)
  })], 1) : _vm._e(), _vm.type === 'deposit' ? _c('div', {
    staticClass: "d-md-none"
  }, [_c('div', {
    staticClass: "deposit-methods"
  }, _vm._l(_vm.depoItems, function (d, i) {
    return _c('div', {
      key: i,
      staticClass: "deposit-method"
    }, [_c('b-button', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle"
      }],
      staticClass: "deposit-toggle d-flex justify-content-between align-items-center",
      attrs: {
        "href": ("#" + (d.depositMethod.replaceAll(' ', '-')))
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
        }
      }
    }, [_c('span', {
      staticClass: "body-paragraph-bold light-theme-title"
    }, [_vm._v(" " + _vm._s(_vm.$t(("account-type.deposit and withdrawal.methods." + (d.depositMethod)))) + " ")]), _c('span', {
      staticClass: "deposit-icon"
    }, [_c('ChevronRight', {
      staticStyle: {
        "width": "30px",
        "height": "30px"
      }
    })], 1)]), _c('b-collapse', {
      staticClass: "mt-3",
      attrs: {
        "id": ("" + (d.depositMethod.replaceAll(' ', '-')))
      }
    }, [_c('div', {
      staticClass: "d-flex justify-content-between align-items-center mb-2"
    }, [_c('span', {
      staticClass: "body-paragraph-1 text-grey-primary-main-500"
    }, [_vm._v(_vm._s(_vm._f("capitalize")(_vm.$t("account-type.deposit and withdrawal.deposit.accept currencies.title"))))]), _c('span', {
      staticClass: "button-small light-theme-title"
    }, [_vm._v(_vm._s(_vm.$t(("account-type.deposit and withdrawal.deposit.accept currencies." + (d.depositMethod.toLowerCase())))))])]), _c('div', {
      staticClass: "d-flex justify-content-between align-items-center mb-2"
    }, [_c('span', {
      staticClass: "body-paragraph-1 text-grey-primary-main-500"
    }, [_vm._v(_vm._s(_vm.$t("account-type.deposit and withdrawal.deposit.commission and fees.title")))]), _c('span', {
      staticClass: "button-small text-primary"
    }, [_vm._v(_vm._s(_vm.$t(("account-type.deposit and withdrawal.deposit.commission and fees." + (d.depositMethod.toLowerCase())))))])]), _c('div', {
      staticClass: "d-flex justify-content-between align-items-center mb-2"
    }, [_c('span', {
      staticClass: "body-paragraph-1 text-grey-primary-main-500"
    }, [_vm._v(_vm._s(_vm.$t("account-type.deposit and withdrawal.deposit.minimum amount.title")))]), _c('span', {
      staticClass: "button-small light-theme-title"
    }, [_vm._v(_vm._s(_vm.$t(("account-type.deposit and withdrawal.deposit.minimum amount." + (d.depositMethod.toLowerCase())))))])]), _c('div', {
      staticClass: "d-flex justify-content-between align-items-center",
      staticStyle: {
        "margin-bottom": "24px"
      }
    }, [_c('span', {
      staticClass: "body-paragraph-1 text-grey-primary-main-500"
    }, [_vm._v(_vm._s(_vm.$t("account-type.deposit and withdrawal.deposit.processing time.title")))]), _c('span', {
      staticClass: "button-small light-theme-title"
    }, [_vm._v(_vm._s(_vm.$t(("account-type.deposit and withdrawal.deposit.processing time." + (d.depositMethod.toLowerCase())))))])])]), _c('hr', {
      staticStyle: {
        "margin-top": "8px",
        "margin-bottom": "33px"
      }
    })], 1);
  }), 0)]) : _vm._e(), _vm.type === 'withdrawal' ? _c('div', {
    staticClass: "d-md-none"
  }, [_c('div', {
    staticClass: "deposit-methods"
  }, _vm._l(_vm.withdrItems, function (d, i) {
    return _c('div', {
      key: i,
      staticClass: "deposit-method"
    }, [_c('b-button', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle"
      }],
      staticClass: "deposit-toggle d-flex justify-content-between align-items-center",
      attrs: {
        "href": ("#" + (d.withdrawMethod.replaceAll(' ', '-')))
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
        }
      }
    }, [_c('span', {
      staticClass: "body-paragraph-bold light-theme-title"
    }, [_vm._v(" " + _vm._s(_vm.$t(("account-type.deposit and withdrawal.methods." + (d.withdrawMethod)))) + " ")]), _c('span', {
      staticClass: "deposit-icon"
    }, [_c('ChevronRight', {
      staticStyle: {
        "width": "30px",
        "height": "30px"
      }
    })], 1)]), _c('b-collapse', {
      staticClass: "mt-3",
      attrs: {
        "id": ("" + (d.withdrawMethod.replaceAll(' ', '-')))
      }
    }, [_c('div', {
      staticClass: "d-flex justify-content-between align-items-center mb-2"
    }, [_c('span', {
      staticClass: "body-paragraph-1 text-grey-primary-main-500"
    }, [_vm._v(_vm._s(_vm._f("capitalize")(_vm.$t("account-type.deposit and withdrawal.withdrawal.accept currencies.title"))))]), _c('span', {
      staticClass: "button-small light-theme-title"
    }, [_vm._v(_vm._s(_vm.$t(("account-type.deposit and withdrawal.withdrawal.accept currencies." + (d.withdrawMethod.toLowerCase())))))])]), _c('div', {
      staticClass: "d-flex justify-content-between align-items-center mb-2"
    }, [_c('span', {
      staticClass: "body-paragraph-1 text-grey-primary-main-500"
    }, [_vm._v(_vm._s(_vm.$t("account-type.deposit and withdrawal.withdrawal.commission and fees.title")))]), _c('span', {
      staticClass: "button-small text-primary"
    }, [_vm._v(_vm._s(_vm.$t(("account-type.deposit and withdrawal.withdrawal.commission and fees." + (d.withdrawMethod.toLowerCase())))))])]), _c('div', {
      staticClass: "d-flex justify-content-between align-items-center mb-2"
    }, [_c('span', {
      staticClass: "body-paragraph-1 text-grey-primary-main-500"
    }, [_vm._v(_vm._s(_vm.$t("account-type.deposit and withdrawal.withdrawal.minimum amount.title")))]), _c('span', {
      staticClass: "button-small light-theme-title"
    }, [_vm._v(_vm._s(_vm.$t(("account-type.deposit and withdrawal.withdrawal.minimum amount." + (d.withdrawMethod.toLowerCase())))))])]), _c('div', {
      staticClass: "d-flex justify-content-between align-items-center",
      staticStyle: {
        "margin-bottom": "24px"
      }
    }, [_c('span', {
      staticClass: "body-paragraph-1 text-grey-primary-main-500"
    }, [_vm._v(_vm._s(_vm.$t("account-type.deposit and withdrawal.withdrawal.processing time.title")))]), _c('span', {
      staticClass: "button-small light-theme-title"
    }, [_vm._v(_vm._s(_vm.$t(("account-type.deposit and withdrawal.withdrawal.processing time." + (d.withdrawMethod.toLowerCase())))))])])]), _c('hr', {
      staticStyle: {
        "margin-top": "8px",
        "margin-bottom": "33px"
      }
    })], 1);
  }), 0)]) : _vm._e(), _c('div', {
    staticClass: "term"
  }, [_c('div', {
    staticClass: "term-title my-4 d-flex align-items-center"
  }, [_c('WarningIcon', {
    staticStyle: {
      "width": "32px",
      "height": "32px",
      "margin-right": "10px"
    }
  }), _c('h6', {
    staticClass: "body-paragraph-bold light-theme-title m-0"
  }, [_vm._v(" " + _vm._s(_vm.$t("account-type.deposit and withdrawal.term-conditions.title")) + " ")])], 1), _c('div', {
    staticClass: "term-content body-small-text light-theme-title"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t("account-type.deposit and withdrawal.term-conditions.contact")) + " ")]), _c('p', [_vm._v(" " + _vm._s(_vm.$t("account-type.deposit and withdrawal.term-conditions.non-liable")) + " ")]), _c('p', [_vm._v(" " + _vm._s(_vm.$t("account-type.deposit and withdrawal.term-conditions.reimbursement")) + " ")]), _c('p', [_vm._v(" " + _vm._s(_vm.$t("account-type.deposit and withdrawal.term-conditions.fund-posted")) + " ")]), _c('p', {
    attrs: {
      "id": "last"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("account-type.deposit and withdrawal.term-conditions.back-office-time")) + " ")])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }