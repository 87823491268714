<template>
	<div>
		<Hero />
		<AccountTypes />
		<DepositAndWithdrawal />
	</div>
</template>

<script>
import Hero from "./components/sections/Hero.vue"
import AccountTypes from "./components/sections/AccountTypes.vue";
import DepositAndWithdrawal from "./components/sections/DepositAndWithdrawal.vue";
export default {
	components: {
		Hero,
		AccountTypes,
		DepositAndWithdrawal,
	},
	metaInfo: {
    title: 'NOZAX: Your money, your way. Account types tailored to your needs',
    meta: [
      { name: 'description', content: 'Start building your portfolio by choosing the right trading account. Tight-spreads, no commission. Tailored to your needs.' },
    ]
  }
};
</script>