var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M13.0572 10.3905C12.5365 10.9112 12.5365 11.7554 13.0572 12.2761L16.781 16L13.0572 19.7239C12.5365 20.2446 12.5365 21.0888 13.0572 21.6095C13.5779 22.1302 14.4221 22.1302 14.9428 21.6095L19.6095 16.9428C20.1302 16.4221 20.1302 15.5779 19.6095 15.0572L14.9428 10.3905C14.4221 9.86983 13.5779 9.86983 13.0572 10.3905Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }