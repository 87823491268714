var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('carousel', {
    staticClass: "d-md-none",
    staticStyle: {
      "margin-top": "57px"
    },
    attrs: {
      "perPage": 1,
      "navigation-enabled": true,
      "paginationEnabled": false,
      "navigation-prev-label": _vm.prevLabel,
      "navigation-next-label": _vm.nextLabel
    }
  }, [_c('slide', [_c('div', {
    staticClass: "account-types-item d-flex justify-content-center align-items-center"
  }, [_c('Zero', {
    staticStyle: {
      "position": "absolute",
      "top": "-55px"
    }
  }), _c('h3', {
    staticClass: "zero-header"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.account-type.zero.title")) + " ")]), _c('div', {
    staticClass: "account-types-content"
  }, [_vm._l(_vm.$t('components.account-type.zero.details'), function (detail) {
    return _c('div', {
      key: detail,
      staticClass: "account-types-text button-small light-theme-title"
    }, [_c('CheckIcon'), _vm._v(" " + _vm._s(detail) + " ")], 1);
  }), _c('b-button', {
    staticClass: "round-btn-16-24",
    attrs: {
      "variant": "primary"
    }
  }, [_c('p', {
    staticClass: "text-white-button button-medium"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.account-type.open account")) + " ")])])], 2)], 1)]), _c('slide', [_c('div', {
    staticClass: "account-types-item account-types-item-low d-flex justify-content-center align-items-center"
  }, [_c('Core', {
    staticStyle: {
      "position": "absolute",
      "top": "-65px"
    }
  }), _c('h3', {
    staticClass: "zero-header"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.account-type.core.title")) + " ")]), _c('div', {
    staticClass: "account-types-content"
  }, [_vm._l(_vm.$t('components.account-type.core.details'), function (detail) {
    return _c('div', {
      key: detail,
      staticClass: "account-types-text button-small light-theme-title"
    }, [_c('CheckIcon'), _vm._v(" " + _vm._s(detail) + " ")], 1);
  }), _c('b-button', {
    staticClass: "round-btn-16-24",
    attrs: {
      "variant": "primary"
    }
  }, [_c('p', {
    staticClass: "text-white-button button-medium"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.account-type.open account")) + " ")])])], 2)], 1)]), _c('slide', [_c('div', {
    staticClass: "account-types-item d-flex justify-content-center align-items-center"
  }, [_c('Nest', {
    staticClass: "nest-illu"
  }), _c('h3', {
    staticStyle: {
      "margin-top": "-70px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.account-type.nest.title")) + " ")]), _c('div', {
    staticClass: "account-types-content"
  }, [_c('div', {
    staticClass: "account-types-text button-small light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.account-type.nest.details")[0]) + " "), _c('br'), _vm._v(" " + _vm._s(_vm.$t("components.account-type.nest.details")[1]) + " ")])])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }