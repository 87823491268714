var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "268",
      "height": "214",
      "viewBox": "0 0 268 214",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M262.466 122.96C262.239 122.758 261.98 122.589 261.687 122.449C260.372 121.827 258.806 121.795 257.388 121.969C256.065 122.128 254.78 122.553 253.589 123.156C249.962 124.99 246.903 129.156 248.345 133.349C248.666 134.279 249.193 135.132 249.869 135.846C252.391 138.5 256.296 138.294 259.054 136.126C261.982 133.822 264.097 129.685 263.785 125.915C263.692 124.77 263.292 123.688 262.466 122.96Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M90.9647 60.2663C22.5554 89.7364 -6.37507 136.801 30.7882 148.32C77.2423 162.719 116.062 101.861 150.001 122.748C183.939 143.636 272.437 144.265 242.937 60.2663",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-dasharray": "10 10"
    }
  }), _c('circle', {
    attrs: {
      "cx": "212.346",
      "cy": "90.4965",
      "r": "5",
      "transform": "rotate(2.99339 212.346 90.4965)",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M82.955 83.9398L79.0719 87.437C75.3188 90.817 76.5823 98.338 81.8936 104.236C87.205 110.133 94.5533 112.174 98.3064 108.794L102.189 105.297L82.955 83.9398Z",
      "stroke": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M102.178 105.297C105.931 101.918 104.668 94.398 99.357 88.5013C94.0463 82.6045 86.6992 80.5639 82.9467 83.9434C79.1943 87.3229 80.4575 94.8428 85.7682 100.74C91.0789 106.636 98.426 108.677 102.178 105.297Z",
      "fill": "white",
      "stroke": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M98.7913 99.568L98.62 99.3778C99.3624 97.9357 99.1557 96.0682 97.947 94.7251C96.0259 92.592 93.9965 92.8985 92.248 93.3527C90.6873 93.7579 90.0156 93.7279 89.1451 92.7611C88.5727 92.1256 88.5437 91.2171 89.0786 90.734C89.6123 90.2534 90.5142 90.376 91.0877 91.0129C91.5707 91.5492 92.3963 91.5924 92.9325 91.1093C93.4688 90.6263 93.512 89.8007 93.029 89.2645C91.8185 87.9193 89.9826 87.5191 88.4714 88.108L88.3008 87.9186C87.8178 87.3823 86.9922 87.3391 86.456 87.8222C85.9197 88.3052 85.8765 89.1308 86.3595 89.667L86.5307 89.857C85.7875 91.2998 85.9949 93.1675 87.2037 94.5096C89.1225 96.6387 91.1505 96.3335 92.9026 95.8833C94.4672 95.4758 95.1325 95.5068 96.0056 96.4736C96.5767 97.1091 96.6069 98.0189 96.0708 98.5007C95.8294 98.7193 95.4977 98.8335 95.1399 98.7855C94.7383 98.7466 94.3553 98.5464 94.063 98.2219C93.58 97.6856 92.7544 97.6424 92.2182 98.1254C91.682 98.6085 91.6387 99.4341 92.1217 99.9703C92.851 100.781 93.834 101.284 94.8877 101.386C94.9499 101.392 95.011 101.396 95.0734 101.4C95.6385 101.429 96.1816 101.33 96.6844 101.133L96.8497 101.316C97.0912 101.584 97.4184 101.729 97.7522 101.747C98.086 101.764 98.4265 101.654 98.6947 101.413C99.2312 100.93 99.2743 100.104 98.7913 99.568V99.568Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M161.702 116.096L165.199 119.98C168.579 123.733 166.538 131.081 160.641 136.392C154.743 141.704 147.222 142.967 143.842 139.214L140.345 135.331L161.702 116.096Z",
      "fill": "white",
      "stroke": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M157.138 132.51C163.035 127.199 165.075 119.852 161.696 116.1C158.316 112.347 150.796 113.611 144.9 118.921C139.003 124.232 136.962 131.579 140.342 135.332C143.721 139.084 151.241 137.821 157.138 132.51Z",
      "fill": "white",
      "stroke": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M146.075 131.93L146.265 131.759C147.707 132.502 149.574 132.295 150.917 131.086C153.05 129.165 152.744 127.136 152.29 125.387C151.885 123.827 151.915 123.155 152.881 122.284C153.517 121.712 154.425 121.683 154.908 122.218C155.389 122.751 155.266 123.653 154.63 124.227C154.093 124.71 154.05 125.536 154.533 126.072C155.016 126.608 155.842 126.651 156.378 126.168C157.723 124.958 158.123 123.122 157.534 121.611L157.724 121.44C158.26 120.957 158.303 120.131 157.82 119.595C157.337 119.059 156.512 119.016 155.975 119.499L155.785 119.67C154.343 118.927 152.475 119.134 151.133 120.343C149.004 122.262 149.309 124.29 149.759 126.042C150.167 127.606 150.136 128.272 149.169 129.145C148.533 129.716 147.624 129.746 147.142 129.21C146.923 128.969 146.809 128.637 146.857 128.279C146.896 127.877 147.096 127.494 147.421 127.202C147.957 126.719 148 125.894 147.517 125.357C147.034 124.821 146.208 124.778 145.672 125.261C144.861 125.99 144.359 126.973 144.256 128.027C144.251 128.089 144.246 128.15 144.243 128.213C144.213 128.778 144.312 129.321 144.51 129.824L144.326 129.989C144.058 130.23 143.913 130.558 143.896 130.891C143.878 131.225 143.988 131.566 144.23 131.834C144.713 132.37 145.538 132.413 146.075 131.93V131.93Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }