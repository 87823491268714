var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "218",
      "height": "161",
      "viewBox": "0 0 218 161",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M215.73 74.9512L210.744 79.4421",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M210.996 74.7051L215.487 79.6916",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M49.7305 123.951L44.7439 128.442",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M44.9961 123.705L49.487 128.692",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('circle', {
    attrs: {
      "cx": "88.2934",
      "cy": "85.2563",
      "r": "3.79337",
      "fill": "#ED6400",
      "stroke": "white",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M1 72.9629C41.8578 78.9915 39.5559 101.63 70.7572 114.735C101.958 127.839 176.302 116.833 140.601 50.1915",
      "stroke": "#07B53B",
      "stroke-width": "1.69839",
      "stroke-linecap": "round",
      "stroke-dasharray": "8.49 8.49"
    }
  }), _c('rect', {
    attrs: {
      "x": "115.5",
      "y": "90.4629",
      "width": "75.7306",
      "height": "35.2539",
      "fill": "#07B53B"
    }
  }), _c('rect', {
    attrs: {
      "x": "121.948",
      "y": "95.6043",
      "width": "74.5845",
      "height": "34.1078",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "1.14612"
    }
  }), _c('circle', {
    attrs: {
      "cx": "159.24",
      "cy": "112.334",
      "r": "10.1192",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M130.516 112.658H144.878",
      "stroke": "#ED6400",
      "stroke-width": "1.71918",
      "stroke-linecap": "round",
      "stroke-dasharray": "5.73 5.73"
    }
  }), _c('path', {
    attrs: {
      "d": "M173.93 112.658H188.292",
      "stroke": "#ED6400",
      "stroke-width": "1.71918",
      "stroke-linecap": "round",
      "stroke-dasharray": "5.73 5.73"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }