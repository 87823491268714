var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "267",
      "height": "214",
      "viewBox": "0 0 267 214",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M261.907 121.96C261.68 121.758 261.422 121.589 261.128 121.449C259.814 120.827 258.248 120.795 256.829 120.969C255.506 121.128 254.222 121.553 253.03 122.156C249.403 123.99 246.344 128.156 247.787 132.349C248.107 133.279 248.635 134.132 249.31 134.846C251.832 137.5 255.738 137.294 258.495 135.126C261.424 132.822 263.539 128.685 263.226 124.915C263.133 123.77 262.734 122.688 261.907 121.96Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M90.9647 60.2663C22.5554 89.7364 -6.37507 136.801 30.7882 148.32C77.2423 162.719 116.062 101.861 150.001 122.748C183.939 143.636 272.437 144.265 242.937 60.2663",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-dasharray": "10 10"
    }
  }), _c('circle', {
    attrs: {
      "cx": "212.158",
      "cy": "89.9076",
      "r": "5.39116",
      "transform": "rotate(2.99339 212.158 89.9076)",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M82.3964 82.9398L78.5133 86.437C74.7602 89.817 76.0237 97.338 81.335 103.236C86.6464 109.133 93.9947 111.174 97.7478 107.794L101.631 104.297L82.3964 82.9398Z",
      "stroke": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M101.62 104.297C105.372 100.918 104.109 93.398 98.7984 87.5013C93.4877 81.6045 86.1406 79.5639 82.3881 82.9434C78.6357 86.3229 79.8989 93.8428 85.2096 99.7395C90.5203 105.636 97.8674 107.677 101.62 104.297Z",
      "fill": "white",
      "stroke": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M98.2327 98.568L98.0614 98.3778C98.8038 96.9357 98.5971 95.0682 97.3884 93.7251C95.4673 91.592 93.4379 91.8985 91.6895 92.3527C90.1287 92.7579 89.457 92.7279 88.5865 91.7611C88.0141 91.1256 87.9851 90.2171 88.52 89.734C89.0537 89.2534 89.9556 89.376 90.5291 90.0129C91.0121 90.5492 91.8377 90.5924 92.3739 90.1093C92.9102 89.6263 92.9534 88.8007 92.4704 88.2645C91.2599 86.9193 89.424 86.5191 87.9128 87.108L87.7422 86.9186C87.2592 86.3823 86.4336 86.3391 85.8974 86.8222C85.3611 87.3052 85.3179 88.1308 85.8009 88.667L85.9721 88.857C85.2289 90.2998 85.4363 92.1675 86.6451 93.5096C88.5639 95.6387 90.5919 95.3335 92.344 94.8833C93.9086 94.4758 94.5739 94.5068 95.447 95.4736C96.0181 96.1091 96.0483 97.0189 95.5122 97.5007C95.2708 97.7193 94.9391 97.8335 94.5814 97.7855C94.1797 97.7466 93.7967 97.5464 93.5044 97.2219C93.0214 96.6856 92.1958 96.6424 91.6596 97.1254C91.1234 97.6085 91.0801 98.4341 91.5631 98.9703C92.2924 99.7813 93.2754 100.284 94.3291 100.386C94.3914 100.392 94.4524 100.396 94.5148 100.4C95.08 100.429 95.623 100.33 96.1258 100.133L96.2911 100.316C96.5326 100.584 96.8598 100.729 97.1936 100.747C97.5274 100.764 97.8679 100.654 98.1361 100.413C98.6726 99.9298 98.7157 99.1041 98.2327 98.568Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M156.579 131.51C162.476 126.199 164.517 118.852 161.137 115.1C157.758 111.347 150.238 112.611 144.341 117.921C138.444 123.232 136.404 130.579 139.783 134.332C143.163 138.084 150.683 136.821 156.579 131.51Z",
      "fill": "white",
      "stroke": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M161.143 115.096L164.641 118.98C168.021 122.733 165.98 130.081 160.082 135.392C154.185 140.704 146.664 141.967 143.283 138.214L139.786 134.331L161.143 115.096Z",
      "fill": "white",
      "stroke": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M145.516 130.93L145.706 130.759C147.148 131.502 149.016 131.295 150.359 130.086C152.492 128.165 152.185 126.136 151.731 124.387C151.326 122.827 151.356 122.155 152.323 121.284C152.958 120.712 153.867 120.683 154.35 121.218C154.83 121.751 154.708 122.653 154.071 123.227C153.535 123.71 153.492 124.536 153.975 125.072C154.458 125.608 155.283 125.651 155.819 125.168C157.165 123.958 157.565 122.122 156.976 120.611L157.165 120.44C157.702 119.957 157.745 119.131 157.262 118.595C156.779 118.059 155.953 118.016 155.417 118.499L155.227 118.67C153.784 117.927 151.916 118.134 150.574 119.343C148.445 121.262 148.75 123.29 149.201 125.042C149.608 126.606 149.577 127.272 148.61 128.145C147.975 128.716 147.065 128.746 146.583 128.21C146.365 127.969 146.25 127.637 146.298 127.279C146.337 126.877 146.537 126.494 146.862 126.202C147.398 125.719 147.441 124.894 146.958 124.357C146.475 123.821 145.65 123.778 145.114 124.261C144.303 124.99 143.8 125.973 143.698 127.027C143.692 127.089 143.688 127.15 143.684 127.213C143.655 127.778 143.754 128.321 143.951 128.824L143.768 128.989C143.5 129.23 143.355 129.558 143.337 129.891C143.32 130.225 143.43 130.566 143.671 130.834C144.154 131.37 144.98 131.413 145.516 130.93Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }