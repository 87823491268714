var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "526",
      "height": "358",
      "viewBox": "0 0 526 358",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M270.18 265.343H121.148C121.148 246.076 133.815 229.689 151.452 223.694C155.861 203.863 173.947 189.01 195.592 189.01C217.206 189.01 235.275 203.832 239.701 223.631C257.434 229.579 270.18 246.013 270.18 265.343Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M217.978 248.38H100.449C100.449 233.395 110.438 220.649 124.347 215.986C127.824 200.562 142.087 189.01 159.157 189.01C176.202 189.01 190.452 200.538 193.942 215.938C207.926 220.564 217.978 233.346 217.978 248.38Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M414.979 96.9677H313.137C313.137 83.8013 321.792 72.6029 333.845 68.5064C336.858 54.9549 349.217 44.8047 364.008 44.8047C378.778 44.8047 391.126 54.9335 394.15 68.4636C406.268 72.528 414.979 83.7585 414.979 96.9677Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M379.303 85.3759H298.988C298.988 75.1354 305.814 66.4255 315.319 63.2394C317.695 52.6993 327.442 44.8047 339.107 44.8047C350.754 44.8047 360.492 52.6827 362.877 63.2061C372.433 66.3673 379.303 75.1021 379.303 85.3759Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M118.773 5.92188C134.612 26.957 176.682 64.2757 218.257 45.2699C270.226 21.5126 334.816 43.0426 321.453 108.375C308.089 173.708 115.778 180.706 167.03 302.888C204.609 392.473 389.612 340.093 398.521 202.746",
      "stroke": "#ED6400",
      "stroke-width": "1.59661",
      "stroke-linecap": "round",
      "stroke-dasharray": "3.87 3.87"
    }
  }), _c('rect', {
    attrs: {
      "y": "6.18164",
      "width": "156.601",
      "height": "82.4215",
      "rx": "8.24215",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M13.4492 96.459H123.001",
      "stroke": "#07B53B",
      "stroke-width": "1.59661",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M13.1055 102.982H79.0426",
      "stroke": "#07B53B",
      "stroke-width": "1.59661",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M356.496 350.469H466.048",
      "stroke": "#07B53B",
      "stroke-width": "1.59661",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M356.152 356.992H422.09",
      "stroke": "#07B53B",
      "stroke-width": "1.59661",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "x": "9.72018",
      "y": "0.798306",
      "width": "155.004",
      "height": "80.8249",
      "rx": "7.44384",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "1.59661"
    }
  }), _c('rect', {
    attrs: {
      "x": "69.3516",
      "y": "22.6641",
      "width": "35.9682",
      "height": "3.09085",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "69.3516",
      "y": "31.9355",
      "width": "35.9682",
      "height": "3.09085",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "69.3516",
      "y": "41.209",
      "width": "24.407",
      "height": "3.09085",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "69.3516",
      "y": "50.4766",
      "width": "17.9841",
      "height": "3.09085",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "41.1927",
      "cy": "29.5325",
      "r": "6.86846",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M26.7736 54.2579C26.7736 52.3637 27.1467 50.4881 27.8715 48.7382C28.5964 46.9882 29.6588 45.3981 30.9982 44.0587C32.3376 42.7194 33.9276 41.6569 35.6776 40.9321C37.4276 40.2072 39.3032 39.8341 41.1973 39.8341C43.0915 39.8341 44.9671 40.2072 46.7171 40.9321C48.467 41.6569 50.0571 42.7194 51.3965 44.0587C52.7358 45.3981 53.7983 46.9882 54.5231 48.7382C55.248 50.4881 55.6211 52.3637 55.6211 54.2579L41.1973 54.2579L26.7736 54.2579Z",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "339.465",
      "y": "170.65",
      "width": "144.169",
      "height": "172.437",
      "fill": "#07B53B"
    }
  }), _c('rect', {
    attrs: {
      "x": "352.177",
      "y": "163.109",
      "width": "142.572",
      "height": "170.84",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "1.59661"
    }
  }), _c('rect', {
    attrs: {
      "x": "390.283",
      "y": "152.394",
      "width": "65.0916",
      "height": "21.0298",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "1.59661"
    }
  }), _c('path', {
    attrs: {
      "d": "M358.348 18.7031L379.143 23.9041",
      "stroke": "#ED6400",
      "stroke-width": "1.59661",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M366.148 31.7012L371.349 10.9062",
      "stroke": "#ED6400",
      "stroke-width": "1.59661",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M265.801 295.918L286.596 301.119",
      "stroke": "#ED6400",
      "stroke-width": "1.59661",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M273.602 308.916L278.803 288.121",
      "stroke": "#ED6400",
      "stroke-width": "1.59661",
      "stroke-linecap": "round"
    }
  }), _c('circle', {
    attrs: {
      "cx": "211.065",
      "cy": "313.764",
      "r": "6.9423",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.59661"
    }
  }), _c('circle', {
    attrs: {
      "cx": "318.781",
      "cy": "235.076",
      "r": "5.35888",
      "fill": "#07B53B"
    }
  }), _c('circle', {
    attrs: {
      "cx": "114.601",
      "cy": "135.136",
      "r": "5.35888",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M414.066 248.305L423.256 257.494L441.635 239.115",
      "stroke": "#07B53B",
      "stroke-width": "2.38172",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('circle', {
    attrs: {
      "cx": "427.602",
      "cy": "248.053",
      "r": "31.3545",
      "stroke": "#ED6400",
      "stroke-width": "1.59661"
    }
  }), _c('circle', {
    attrs: {
      "cx": "518.694",
      "cy": "251.032",
      "r": "6.54974",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "228.755",
      "y": "137.576",
      "width": "72.2505",
      "height": "40.058",
      "rx": "3.85566",
      "transform": "rotate(-12.4135 228.755 137.576)",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "1.33244"
    }
  }), _c('rect', {
    attrs: {
      "x": "240.506",
      "y": "140.311",
      "width": "72.2505",
      "height": "40.058",
      "rx": "3.85566",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "1.33244"
    }
  }), _c('rect', {
    attrs: {
      "x": "239.84",
      "y": "156.508",
      "width": "73.583",
      "height": "6.13191",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "291.195",
      "y": "146.162",
      "width": "18.3957",
      "height": "5.74867",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }