var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M20.0026 33.334C27.3664 33.334 33.3359 27.3644 33.3359 20.0007C33.3359 12.6369 27.3664 6.66732 20.0026 6.66732C12.6388 6.66732 6.66927 12.6369 6.66927 20.0007C6.66927 27.3644 12.6388 33.334 20.0026 33.334ZM20.0026 36.6673C29.2073 36.6673 36.6693 29.2054 36.6693 20.0007C36.6693 10.7959 29.2073 3.33398 20.0026 3.33398C10.7979 3.33398 3.33594 10.7959 3.33594 20.0007C3.33594 29.2054 10.7979 36.6673 20.0026 36.6673Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M19.9987 11.668C19.0782 11.668 18.332 12.4142 18.332 13.3346C18.332 14.2551 19.0782 15.0013 19.9987 15.0013C20.9192 15.0013 21.6654 14.2551 21.6654 13.3346C21.6654 12.4142 20.9192 11.668 19.9987 11.668ZM19.9987 18.3346C19.0782 18.3346 18.332 19.0808 18.332 20.0013V26.668C18.332 27.5884 19.0782 28.3346 19.9987 28.3346C20.9192 28.3346 21.6654 27.5884 21.6654 26.668V20.0013C21.6654 19.0808 20.9192 18.3346 19.9987 18.3346Z",
      "fill": "#ED6400"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }