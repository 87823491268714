var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h2', {
    staticClass: "text-center light-theme-title",
    attrs: {
      "id": "t"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("account-type.pick favorite.title")) + " ")]), _c('div', {
    staticClass: "account-types"
  }, [_c('div', {
    staticClass: "account-types-items d-none d-md-flex flex-column flex-md-row justify-content-center align-items-center"
  }, [_c('div', {
    staticClass: "account-types-item d-flex justify-content-center align-items-center"
  }, [_c('Zero', {
    staticStyle: {
      "position": "absolute",
      "top": "-55px"
    }
  }), _c('h3', {
    staticClass: "zero-header"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.account-type.zero.title")) + " ")]), _c('div', {
    staticClass: "account-types-content"
  }, [_vm._l(_vm.$t('components.account-type.zero.details'), function (detail) {
    return _c('div', {
      key: detail,
      staticClass: "account-types-text light-theme-title"
    }, [_c('CheckIcon'), _vm._v(" " + _vm._s(detail) + " ")], 1);
  }), _vm.portal_url ? _c('b-button', {
    staticClass: "round-btn-16-24",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.goToSignUp
    }
  }, [_c('p', {
    staticClass: "text-white-button button-medium"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.account-type.open account")) + " ")])]) : _vm._e()], 2)], 1), _c('div', {
    staticClass: "account-types-item account-types-item-low d-flex justify-content-center align-items-center"
  }, [_c('Core', {
    staticStyle: {
      "position": "absolute",
      "top": "-65px"
    }
  }), _c('h3', {
    staticClass: "zero-header"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.account-type.core.title")) + " ")]), _c('div', {
    staticClass: "account-types-content"
  }, [_vm._l(_vm.$t('components.account-type.core.details'), function (detail) {
    return _c('div', {
      key: detail,
      staticClass: "account-types-text light-theme-title"
    }, [_c('CheckIcon'), _vm._v(" " + _vm._s(detail) + " ")], 1);
  }), _vm.portal_url ? _c('b-button', {
    staticClass: "round-btn-16-24",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.goToSignUp
    }
  }, [_c('p', {
    staticClass: "text-white-button button-medium"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.account-type.open account")) + " ")])]) : _vm._e()], 2)], 1), _c('div', {
    staticClass: "account-types-item d-flex justify-content-center align-items-center"
  }, [_c('Cent', {
    staticStyle: {
      "position": "absolute",
      "top": "-55px"
    }
  }), _c('h3', {
    staticClass: "zero-header"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.account-type.cent.title")) + " ")]), _c('div', {
    staticClass: "account-types-content"
  }, [_vm._l(_vm.$t('components.account-type.cent.details'), function (detail) {
    return _c('div', {
      key: detail,
      staticClass: "account-types-text light-theme-title"
    }, [_c('CheckIcon'), _vm._v(" " + _vm._s(detail) + " ")], 1);
  }), _vm.portal_url ? _c('b-button', {
    staticClass: "round-btn-16-24",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.goToSignUp
    }
  }, [_c('p', {
    staticClass: "text-white-button button-medium"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.account-type.open account")) + " ")])]) : _vm._e()], 2)], 1)]), _c('div', {
    staticClass: "d-flex d-md-none flex-column"
  }, [_c('div', {
    staticClass: "box",
    on: {
      "click": function ($event) {
        return _vm.closeOther(0);
      }
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("components.account-type.zero.title")) + " ")]), _vm.boxes[0] ? _c('ChevronUp') : _c('ChevronDown')], 1), _vm.boxes[0] ? _c('div', {
    staticClass: "account-types-item d-flex justify-content-center align-items-center"
  }, [_c('Zero', {
    staticStyle: {
      "position": "absolute",
      "top": "-65px",
      "left": "30px"
    }
  }), _c('h3', {
    staticClass: "zero-header"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.account-type.zero.title")) + " ")]), _c('div', {
    staticClass: "account-types-content"
  }, [_vm._l(_vm.$t('components.account-type.zero.details'), function (detail) {
    return _c('div', {
      key: detail,
      staticClass: "account-types-text light-theme-title"
    }, [_c('CheckIcon'), _vm._v(" " + _vm._s(detail) + " ")], 1);
  }), _vm.portal_url ? _c('b-button', {
    staticClass: "round-btn-16-24",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.goToSignUp
    }
  }, [_c('p', {
    staticClass: "text-white-button button-medium"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.account-type.open account")) + " ")])]) : _vm._e()], 2)], 1) : _vm._e(), _c('div', {
    staticClass: "box",
    on: {
      "click": function ($event) {
        return _vm.closeOther(1);
      }
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("components.account-type.core.title")) + " ")]), _vm.boxes[1] ? _c('ChevronUp') : _c('ChevronDown')], 1), _vm.boxes[1] ? _c('div', {
    staticClass: "account-types-item account-types-item-low d-flex justify-content-center align-items-center"
  }, [_c('Core', {
    staticStyle: {
      "position": "absolute",
      "top": "-65px"
    }
  }), _c('h3', {
    staticClass: "zero-header"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.account-type.core.title")) + " ")]), _c('div', {
    staticClass: "account-types-content"
  }, [_vm._l(_vm.$t('components.account-type.core.details'), function (detail) {
    return _c('div', {
      key: detail,
      staticClass: "account-types-text light-theme-title"
    }, [_c('CheckIcon'), _vm._v(" " + _vm._s(detail) + " ")], 1);
  }), _vm.portal_url ? _c('b-button', {
    staticClass: "round-btn-16-24",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.goToSignUp
    }
  }, [_c('p', {
    staticClass: "text-white-button button-medium"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.account-type.open account")) + " ")])]) : _vm._e()], 2)], 1) : _vm._e(), _c('div', {
    staticClass: "box",
    on: {
      "click": function ($event) {
        return _vm.closeOther(2);
      }
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("components.account-type.cent.title")) + " ")]), _vm.boxes[2] ? _c('ChevronUp') : _c('ChevronDown')], 1), _vm.boxes[2] ? _c('div', {
    staticClass: "account-types-item account-types-item-low d-flex justify-content-center align-items-center"
  }, [_c('Cent', {
    staticStyle: {
      "position": "absolute",
      "top": "-65px"
    }
  }), _c('h3', {
    staticClass: "zero-header"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.account-type.cent.title")) + " ")]), _c('div', {
    staticClass: "account-types-content"
  }, [_vm._l(_vm.$t('components.account-type.cent.details'), function (detail) {
    return _c('div', {
      key: detail,
      staticClass: "account-types-text light-theme-title"
    }, [_c('CheckIcon'), _vm._v(" " + _vm._s(detail) + " ")], 1);
  }), _vm.portal_url ? _c('b-button', {
    staticClass: "round-btn-16-24",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.goToSignUp
    }
  }, [_c('p', {
    staticClass: "text-white-button button-medium"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.account-type.open account")) + " ")])]) : _vm._e()], 2)], 1) : _vm._e()])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }