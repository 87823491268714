var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M10.3905 18.9428C10.9112 19.4635 11.7554 19.4635 12.2761 18.9428L16 15.219L19.7239 18.9428C20.2446 19.4635 21.0888 19.4635 21.6095 18.9428C22.1302 18.4221 22.1302 17.5779 21.6095 17.0572L16.9428 12.3905C16.4221 11.8698 15.5779 11.8698 15.0572 12.3905L10.3905 17.0572C9.86982 17.5779 9.86982 18.4221 10.3905 18.9428Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }