var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M10.0771 8.91862L13.2377 5.75802C13.3877 5.60828 13.4721 5.40509 13.4723 5.19314C13.4724 4.98119 13.3884 4.77785 13.2387 4.62785C13.089 4.47785 12.8858 4.39347 12.6738 4.39328C12.4619 4.3931 12.2585 4.47711 12.1085 4.62685L8.94792 7.78746L5.78731 4.62685C5.63731 4.47685 5.43386 4.39258 5.22173 4.39258C5.00959 4.39258 4.80615 4.47685 4.65615 4.62685C4.50615 4.77685 4.42188 4.9803 4.42188 5.19243C4.42188 5.40457 4.50615 5.60801 4.65615 5.75802L7.81676 8.91862L4.65615 12.0792C4.50615 12.2292 4.42188 12.4327 4.42188 12.6448C4.42188 12.8569 4.50615 13.0604 4.65615 13.2104C4.80615 13.3604 5.00959 13.4447 5.22173 13.4447C5.43386 13.4447 5.63731 13.3604 5.78731 13.2104L8.94792 10.0498L12.1085 13.2104C12.2585 13.3604 12.462 13.4447 12.6741 13.4447C12.8862 13.4447 13.0897 13.3604 13.2397 13.2104C13.3897 13.0604 13.474 12.8569 13.474 12.6448C13.474 12.4327 13.3897 12.2292 13.2397 12.0792L10.0771 8.91862Z",
      "fill": "#1D2326"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }