var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hero-custom"
  }, [_c('div', {
    staticClass: "hero-custom__content mx-auto"
  }, [_c('h1', {
    staticClass: "hero-custom__title"
  }, [_c('span', {
    staticClass: "text-primary"
  }, [_vm._v(" " + _vm._s(_vm.$t("account-type.hero.header")) + " ")]), _c('span', {
    staticClass: "light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("account-type.hero.header-green")) + " ")])]), _c('h2', {
    staticClass: "hero-custom__text hero-subtitle text-grey-primary-main-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("account-type.hero.sub-header1")) + " "), _c('br', {
    staticClass: "d-none d-md-inline"
  }), _vm._v(" " + _vm._s(_vm.$t("account-type.hero.sub-header2")) + " ")]), _c('div', {
    staticClass: "hero-buttons d-flex"
  }, [_c('a', {
    attrs: {
      "href": "https://nozax.kb.help/accounts",
      "target": "_blank"
    }
  }, [_c('b-button', {
    staticClass: "round-btn-16-24",
    attrs: {
      "variant": "primary"
    }
  }, [_c('p', {
    staticClass: "button-medium text-white-button"
  }, [_vm._v(" " + _vm._s(_vm.$t("account-type.hero.learn more")) + " ")])])], 1)])]), _c('div', {
    staticClass: "d-none d-xl-block"
  }, [_c('HomeHero')], 1), _c('div', {
    staticClass: "d-none d-md-block d-xl-none"
  }, [_c('HomeTabletHero')], 1), _c('div', {
    staticClass: "d-block d-md-none"
  }, [_c('HomeMobileHero')], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }