<template>
  <div class="deposit section">
    <h2 class="light-theme-title text-center">
      {{ $t("account-type.deposit and withdrawal.title") }}
    </h2>
    <!-- Deposit and Withdrawal -->
    <div class="deposit-buttons" style="margin-bottom: 39px">
      <div
        @click="handleClick('deposit')"
        class="deposit-button"
        style="text-align: center"
        :style="type == 'deposit' ? 'border-bottom: 3px solid #07b53b;' : ''"
      >
        <p class="light-theme-title button-1 roboto">
          {{ $t("account-type.deposit and withdrawal.deposit.title") }}
        </p>
      </div>
      <div
        @click="handleClick('withdrawal')"
        class="deposit-button"
        style="text-align: center"
        :style="type == 'withdrawal' ? 'border-bottom: 3px solid #07b53b' : ''"
      >
        <p class="light-theme-title button-1 roboto">
          {{ $t("account-type.deposit and withdrawal.withdrawal.title") }}
        </p>
      </div>
    </div>

    <!-- PC table -->
    <div v-if="type === 'deposit'" class="d-none d-md-block">
      <b-table
        responsive
        :items="depoItems"
        :fields="depoFields"
        class="table table-borderless"
        thead-class="header"
        tbody-tr-class="body-row"
        thead-tr-class="head-row"
      >
        <!-- Optional default data cell scoped slot -->
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(depositMethod)="data">
          <div style="display: flex; flex-wrap: nowrap; align-items: center">
            {{
              $t(`account-type.deposit and withdrawal.methods.${data.value}`)
            }}
          </div>
        </template>
        <template #cell(acceptedCurrencies)="data">
          <div style="display: flex; flex-wrap: nowrap; align-items: center">
            {{
              $t(`account-type.deposit and withdrawal.deposit.accept currencies.${data.item.depositMethod.toLowerCase()}`)
            }}
          </div>
        </template>
        <template #cell(commissionAndFees)="data">
            
          <div style="display: flex; flex-wrap: nowrap; align-items: center">
            {{
              $t(
                `account-type.deposit and withdrawal.deposit.commission and fees.${data.item.depositMethod.toLowerCase()}`
              )
            }}
          </div>
        </template>
        <template #cell(minimumAmount)="data">
          <div style="display: flex; flex-wrap: nowrap; align-items: center">
            {{
              $t(
                `account-type.deposit and withdrawal.deposit.minimum amount.${data.item.depositMethod.toLowerCase()}`
              )
            }}
          </div>
        </template>
        <template #cell(processingTime)="data">
          <div style="display: flex; flex-wrap: nowrap; align-items: center">
            {{
              $t(
                `account-type.deposit and withdrawal.deposit.processing time.${data.item.depositMethod.toLowerCase()}`
              )
            }}
          </div>
        </template>
      </b-table>
    </div>
    <div v-if="type === 'withdrawal'" class="d-none d-md-block">
      <b-table
        responsive
        :items="withdrItems"
        :fields="withdrFields"
        class="table table-borderless"
        thead-class="header"
        tbody-tr-class="body-row"
        thead-tr-class="head-row"
      >
        <!-- Optional default data cell scoped slot -->
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(withdrawMethod)="data">
          <div style="display: flex; flex-wrap: nowrap; align-items: center">
            {{
              $t(`account-type.deposit and withdrawal.methods.${data.value}`)
            }}
          </div>
        </template>
        <template #cell(acceptedCurrencies)="data">
          <div style="display: flex; flex-wrap: nowrap; align-items: center">
            {{
              $t(`account-type.deposit and withdrawal.withdrawal.accept currencies.${data.item.withdrawMethod.toLowerCase()}`)
            }}
          </div>
        </template>
        <template #cell(commissionAndFees)="data">
          <div style="display: flex; flex-wrap: nowrap; align-items: center">
            {{
              $t(
                `account-type.deposit and withdrawal.withdrawal.commission and fees.${data.item.withdrawMethod.toLowerCase()}`
              )
            }}
          </div>
        </template>
        <template #cell(minimumAmount)="data">
          <div style="display: flex; flex-wrap: nowrap; align-items: center">
            {{
              $t(
                `account-type.deposit and withdrawal.withdrawal.minimum amount.${data.item.withdrawMethod.toLowerCase()}`
              )
            }}
          </div>
        </template>
        <template #cell(processingTime)="data">
          <div style="display: flex; flex-wrap: nowrap; align-items: center">
            {{
              $t(
                `account-type.deposit and withdrawal.withdrawal.processing time.${data.item.withdrawMethod.toLowerCase()}`
              )
            }}
          </div>
        </template>
      </b-table>
    </div>

    <!-- Mobile table -->

    <div v-if="type === 'deposit'" class="d-md-none">
      <div class="deposit-methods">
        <div v-for="(d, i) in depoItems" :key="i" class="deposit-method">
          <b-button
            v-b-toggle
            :href="`#${d.depositMethod.replaceAll(' ', '-')}`"
            @click.prevent
            class="deposit-toggle d-flex justify-content-between align-items-center"
          >
            <span class="body-paragraph-bold light-theme-title">
              {{
                $t(
                  `account-type.deposit and withdrawal.methods.${d.depositMethod}`
                )
              }}
            </span>
            <span class="deposit-icon">
              <ChevronRight style="width: 30px; height: 30px" />
            </span>
          </b-button>
          <b-collapse
            :id="`${d.depositMethod.replaceAll(' ', '-')}`"
            class="mt-3"
          >
            <div class="d-flex justify-content-between align-items-center mb-2">
              <span class="body-paragraph-1 text-grey-primary-main-500">{{
                $t(
                  "account-type.deposit and withdrawal.deposit.accept currencies.title"
                ) | capitalize
              }}</span>
              <span class="button-small light-theme-title">{{
                $t(
                  `account-type.deposit and withdrawal.deposit.accept currencies.${d.depositMethod.toLowerCase()}`
    )
                
              }}</span>
            </div>

            <div class="d-flex justify-content-between align-items-center mb-2">
              <span class="body-paragraph-1 text-grey-primary-main-500">{{
                $t(
                  "account-type.deposit and withdrawal.deposit.commission and fees.title"
                )
              }}</span>
              <span class="button-small text-primary">{{
                $t(
                  `account-type.deposit and withdrawal.deposit.commission and fees.${d.depositMethod.toLowerCase()}`
                )
              }}</span>
            </div>

            <div class="d-flex justify-content-between align-items-center mb-2">
              <span class="body-paragraph-1 text-grey-primary-main-500">{{
                $t(
                  "account-type.deposit and withdrawal.deposit.minimum amount.title"
                )
              }}</span>
              <span class="button-small light-theme-title">{{
                $t(
                  `account-type.deposit and withdrawal.deposit.minimum amount.${d.depositMethod.toLowerCase()}`
                )
              }}</span>
            </div>

            <div
              class="d-flex justify-content-between align-items-center"
              style="margin-bottom: 24px"
            >
              <span class="body-paragraph-1 text-grey-primary-main-500">{{
                $t(
                  `account-type.deposit and withdrawal.deposit.processing time.title`
                )
              }}</span>
              <span class="button-small light-theme-title">{{
                $t(
                  `account-type.deposit and withdrawal.deposit.processing time.${d.depositMethod.toLowerCase()}`
                )
              }}</span>
            </div>
          </b-collapse>
          <hr style="margin-top: 8px; margin-bottom: 33px" />
        </div>
      </div>
    </div>

    <div v-if="type === 'withdrawal'" class="d-md-none">
      <div class="deposit-methods">
        <div v-for="(d, i) in withdrItems" :key="i" class="deposit-method">
          <b-button
            v-b-toggle
            :href="`#${d.withdrawMethod.replaceAll(' ', '-')}`"
            @click.prevent
            class="deposit-toggle d-flex justify-content-between align-items-center"
          >
            <span class="body-paragraph-bold light-theme-title">
              {{
                $t(
                  `account-type.deposit and withdrawal.methods.${d.withdrawMethod}`
                )
              }}
            </span>
            <span class="deposit-icon">
              <ChevronRight style="width: 30px; height: 30px" />
            </span>
          </b-button>
          <b-collapse
            :id="`${d.withdrawMethod.replaceAll(' ', '-')}`"
            class="mt-3"
          >
            <div class="d-flex justify-content-between align-items-center mb-2">
              <span class="body-paragraph-1 text-grey-primary-main-500">{{
                $t(
                  "account-type.deposit and withdrawal.withdrawal.accept currencies.title"
                ) | capitalize
              }}</span>
              <span class="button-small light-theme-title">{{
                $t(
                  `account-type.deposit and withdrawal.withdrawal.accept currencies.${d.withdrawMethod.toLowerCase()}`
                )
              }}</span>
            </div>

            <div class="d-flex justify-content-between align-items-center mb-2">
              <span class="body-paragraph-1 text-grey-primary-main-500">{{
                $t(
                  `account-type.deposit and withdrawal.withdrawal.commission and fees.title`
                )
              }}</span>
              <span class="button-small text-primary">{{
                $t(
                  `account-type.deposit and withdrawal.withdrawal.commission and fees.${d.withdrawMethod.toLowerCase()}`
                )
              }}</span>
            </div>

            <div class="d-flex justify-content-between align-items-center mb-2">
              <span class="body-paragraph-1 text-grey-primary-main-500">{{
                $t(
                  `account-type.deposit and withdrawal.withdrawal.minimum amount.title`
                )
              }}</span>
              <span class="button-small light-theme-title">{{
                $t(
                  `account-type.deposit and withdrawal.withdrawal.minimum amount.${d.withdrawMethod.toLowerCase()}`
                )
              }}</span>
            </div>

            <div
              class="d-flex justify-content-between align-items-center"
              style="margin-bottom: 24px"
            >
              <span class="body-paragraph-1 text-grey-primary-main-500">{{
                $t(
                  `account-type.deposit and withdrawal.withdrawal.processing time.title`
                )
              }}</span>
              <span class="button-small light-theme-title">{{
                $t(
                  `account-type.deposit and withdrawal.withdrawal.processing time.${d.withdrawMethod.toLowerCase()}`
                )
              }}</span>
            </div>
          </b-collapse>
          <hr style="margin-top: 8px; margin-bottom: 33px" />
        </div>
      </div>
    </div>

    <!-- Term and condition -->
    <div class="term">
      <div class="term-title my-4 d-flex align-items-center">
        <WarningIcon style="width: 32px; height: 32px; margin-right: 10px" />
        <h6 class="body-paragraph-bold light-theme-title m-0">
          {{ $t("account-type.deposit and withdrawal.term-conditions.title") }}
        </h6>
      </div>
      <div class="term-content body-small-text light-theme-title">
        <p>
          {{
            $t("account-type.deposit and withdrawal.term-conditions.contact")
          }}
        </p>
        <p>
          {{
            $t("account-type.deposit and withdrawal.term-conditions.non-liable")
          }}
        </p>
        <p>
          {{
            $t(
              "account-type.deposit and withdrawal.term-conditions.reimbursement"
            )
          }}
        </p>
        <p>
          {{
            $t(
              "account-type.deposit and withdrawal.term-conditions.fund-posted"
            )
          }}
        </p>
        <p id="last">
          {{
            $t(
              "account-type.deposit and withdrawal.term-conditions.back-office-time"
            )
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import WarningIcon from "@/components/icons/WarningIcon.vue";
import ChevronRight from "@/components/icons/ChevronRight.vue";
import { Withdr, Depo } from "./fakeTableData";

export default {
  components: { WarningIcon, ChevronRight },
  data() {
    return {
      type: "deposit",
      depoItems: [],
      withdrItems: [],
    };
  },
  created() {
    this.depoItems = Depo;
    this.withdrItems = Withdr;
  },
  methods: {
    handleClick(type) {
      this.type = type;
    },
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },

  computed: {
    depoFields() {
      return [
        {
          key: "index",
          thClass: "align-middle invisible",
          tdClass: "text-primary align-middle",
          thStyle: { width: "48px" },
        },
        {
          key: "depositMethod",
          tdClass: "align-middle body-paragraph-14 ",
          thClass:
            "align-middle  light-theme-title body-black-text-to-tablet-bold  min-width-170px s",
          label: `${this.$t(
            "account-type.deposit and withdrawal.deposit.deposit method"
          )}`,
          thStyle: { width: "500px" },
        },
        {
          key: "acceptedCurrencies",
          tdClass: "align-middle primary-text ",
          thClass:
            "align-middle  light-theme-title body-black-text-to-tablet-bold s",
          label: `${this.$t(
            "account-type.deposit and withdrawal.deposit.accept currencies.title"
          )}`,
          thStyle: { width: "500px" },
        },
        {
          key: "commissionAndFees",
          tdClass: "align-middle body-bold-text text-primary ",
          thClass:
            "align-middle  light-theme-title body-black-text-to-tablet-bold s",
          label: `${this.$t(
            "account-type.deposit and withdrawal.deposit.commission and fees.title"
          )}`,
          thStyle: { width: "500px" },
        },
        {
          key: "minimumAmount",
          tdClass: "align-middle primary-text ",
          thClass:
            "align-middle  light-theme-title body-black-text-to-tablet-bold s",
          label: `${this.$t(
            "account-type.deposit and withdrawal.deposit.minimum amount.title"
          )}`,
          thStyle: { width: "500px" },
        },
        {
          key: "processingTime",
          tdClass: "align-middle primary-text",
          thClass:
            "align-middle  light-theme-title body-black-text-to-tablet-bold s",
          label: `${this.$t(
            "account-type.deposit and withdrawal.deposit.processing time.title"
          )}`,
          thStyle: { width: "500px" },
        },
      ];
    },
    withdrFields() {
      return [
        {
          key: "index",
          thClass: "align-middle invisible",
          tdClass: "text-primary align-middle",
          thStyle: { width: "48px" },
        },
        {
          key: "withdrawMethod",
          tdClass: "align-middle body-paragraph-14",
          thClass:
            "align-middle  light-theme-title body-black-text-to-tablet-bold min-width-170px s",
          label: `${this.$t(
            "account-type.deposit and withdrawal.withdrawal.withdrawal method"
          )}`,
          thStyle: { width: "500px" },
        },
        {
          key: "acceptedCurrencies",
          tdClass: "align-middle primary-text",
          thClass:
            "align-middle  light-theme-title body-black-text-to-tablet-bold s",
          label: `${this.$t(
            "account-type.deposit and withdrawal.withdrawal.accept currencies.title"
          )}`,
          thStyle: { width: "500px" },
        },
        {
          key: "commissionAndFees",
          tdClass: "align-middle body-bold-text text-primary",
          thClass:
            "align-middle  light-theme-title body-black-text-to-tablet-bold s",
          label: `${this.$t(
            "account-type.deposit and withdrawal.withdrawal.commission and fees.title"
          )}`,
          thStyle: { width: "500px" },
        },
        {
          key: "minimumAmount",
          tdClass: "align-middle primary-text",
          thClass:
            "align-middle  light-theme-title body-black-text-to-tablet-bold s",
          label: `${this.$t(
            "account-type.deposit and withdrawal.withdrawal.minimum amount.title"
          )}`,
          thStyle: { width: "500px" },
        },
        {
          key: "processingTime",
          tdClass: "align-middle primary-text",
          thClass:
            "align-middle  light-theme-title body-black-text-to-tablet-bold s",
          label: `${this.$t(
            "account-type.deposit and withdrawal.withdrawal.processing time.title"
          )}`,
          thStyle: { width: "500px" },
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.body-paragraph-1 {
  @media (max-width: 767px) {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 120% !important;
  }
}
.button-1 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
}
.deposit {
  @media (max-width: 767px) {
    margin-top: 81px !important;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    margin-top: 111px !important;
  }
  @media (min-width: 1200px) {
    margin-top: 112px !important;
  }
  .deposit-methods {
    .deposit-method {
      .deposit-toggle {
        border: 0;
        background: white;
        color: var(--text-color);
        padding: 0 !important;
        display: flex;
        justify-content: space-between;
        width: 100%;
        &:focus {
          box-shadow: none !important;
        }
      }
      .collapsed {
        .deposit-icon {
          transform: rotate(0deg);
          transition: all 0.2s linear;
        }
      }
      .not-collapsed {
        .deposit-icon {
          transform: rotate(90deg);
          transition: all 0.2s linear;
        }
      }
    }
  }
  .deposit-buttons {
    display: flex;
    // gap: 20px;
    .deposit-button {
      cursor: pointer;
      padding: 16px 32px;
      flex-grow: 1;
      background: #ffffff;
      color: #39454c;
      border: 0;
      max-width: 200px;
      line-height: 0;
      width: 50%;

      &:focus {
        box-shadow: none !important;
      }
    }
  }
}

::v-deep .header {
  box-shadow: inset 0px -1px 0px #e7e7ed;
}
::v-deep .body-row {
  height: 64px;
}
::v-deep .head-row {
  height: 48px;
}
a {
  /* background-color: #fff !important; */
  height: 27px;
}

h2 {
  @media (max-width: 767px) {
    margin-bottom: 30px !important;
  }
  @media (min-width: 768px) {
    margin-bottom: 40px !important;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
  }
  @media (min-width: 1200px) {
  }
}
#last {
  margin-bottom: 40px !important;
}

::v-deep .table {
  text-align: left !important;
}

.s {
  @media (max-width: 767px) {
    margin-bottom: 30px !important;
  }
  @media (min-width: 768px) {
    margin-bottom: 40px !important;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
  }
  @media (min-width: 1200px) {
    width: calc((1100px - 30px) / 5);
  }
}
</style>
